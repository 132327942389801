@use 'libs/theme' as *;

.swiper {
	@include multiplier {
		position: relative;
		align-items: flex-end;
		width: var(--grid-columns-12);
		margin-bottom: var(--responsive-spacing-48);
		overflow: visible;

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-10);
		}
	}

	&__bar {
		padding: var(--size-6);
		color: var(--color-dark-blue-base);
		background-color: var(--color-light-blue-base);
		border-radius: var(--size-2);
	}

	&__bar_current {
		padding: var(--size-6);
		color: var(--color-neutral-white);
		background-color: var(--color-dark-blue-base);
		border-radius: var(--size-2);
	}
}

.swiper_item {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	align-self: flex-end;
	width: var(--grid-columns-2);
	margin-right: var(--size-4);
	hyphens: auto;

	@media (min-width: $breakpoint-lg) {
		min-width: 110px;
	}
}

.loader_slider {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 400px;
}

.nav_right,
.nav_left {
	position: absolute;
	bottom: calc(50% - 24px);
	z-index: var(--z-index-overlay);
	display: inline-flex;
	width: var(--size-8);
	height: var(--size-8);
	padding: var(--size-2);
	border-radius: var(--border-radius-round);

	@media (min-width: $breakpoint-lg) {
		width: var(--size-12);
		height: var(--size-12);
		padding: var(--size-3);
	}

	svg {
		color: var(--color-neutral-white);
	}
}

$iconSize: 32px; // plus padding

@media (min-width: $breakpoint-lg) {
	$iconSize: 48px; // plus padding
}

.nav_left {
	left: calc(($iconSize / 2) * -1);
}

.nav_right {
	right: calc(($iconSize / 2) * -1);
}

.left_overlay,
.right_overlay {
	position: absolute;
	bottom: 0;
	z-index: var(--z-index-content-highlighted);
	width: 218px;
	height: 500px;
}

.left_overlay {
	left: -218px;
	background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.right_overlay {
	right: -218px;
	background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.bold {
	font-weight: var(--font-weight-semi-bold);
}

.dates {
	font-size: var(--font-size-14);
	white-space: nowrap;
}

.measure {
	font-size: var(--font-size-12);
}

.disable {
	display: none;
}
