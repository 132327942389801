@use 'libs/theme' as *;

.form_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
}

.tabs_wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	max-width: var(--grid-columns-12);
	margin-bottom: var(--responsive-spacing-48);
	padding: 0 var(--size-6);
	overflow-x: scroll;
	white-space: nowrap;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}

	.swiper {
		display: flex;
		align-items: center;
		height: 48px;
		border-bottom: 1px solid var(--color-dark-blue-white95);
	}

	.swiwper_slide {
		width: auto;
	}

	button {
		padding: var(--size-3) 0;
		color: var(--color-primary);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
		background-color: transparent;
		cursor: pointer;

		&:disabled {
			opacity: 0.25;
		}
	}
}

.section {
	width: var(--grid-columns-12);
	margin-top: var(--size-1);
}

.section:first-child {
	.banner_wrapper {
		display: block;
	}
}

.banner_wrapper {
	display: none;
	max-width: 480px;
}

.row {
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow-x: hidden;
}

.column {
	display: flex;
	flex-direction: column;
	gap: var(--size-9);
	width: 100%;
	max-width: 480px;

	label {
		> span {
			margin-bottom: var(--size-3) !important;
		}
	}

	fieldset > label {
		margin-bottom: var(--size-1);
	}
}

.error {
	margin-top: var(--size-2);
	color: var(--color-state-error);
	font-size: var(--font-size-xsmall);
}

.buttonError {
	color: var(--color-state-error);
}

.buttonActive {
	border-bottom: 2px solid var(--color-secondary);
}

.confirmaion_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
}

.confirmation_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);

	h2 {
		@include h4;

		margin-top: var(--responsive-spacing-36);
		margin-bottom: var(--size-3);
	}
}
