@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	width: 100%;

	a {
		width: 100%;
		text-decoration: none;
	}
}
