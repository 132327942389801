@use 'libs/theme' as *;

.form_container {
	width: var(--grid-columns-12);
	padding: var(--size-9) var(--size-6) var(--size-12);
	background-color: var(--color-light-blue-white50);
	border-radius: var(--border-radius-large);

	input {
		background-color: var(--color-neutral-white);
	}

	label[class*='select'] > div > div {
		background-color: var(--color-neutral-white);
	}

	@media (min-width: $breakpoint-sm) {
		padding: var(--size-9) var(--size-9) var(--size-12);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		padding: var(--size-12) var(--size-12) var(--size-16);
	}
}

.form_header {
	display: flex;
	flex-direction: row;
	gap: var(--size-4);
	width: var(--grid-columns-12);
	margin-bottom: var(--responsive-spacing-36);
	padding: var(--size-4);
	border-radius: var(--border-radius-large);

	&__success {
		background: var(--color-light-blue-base);
	}

	&__error {
		background: var(--color-signal-red70);
	}
}

.form_footer {
	display: flex;
	width: var(--grid-columns-12);
	margin-top: var(--size-1);
	padding: var(--responsive-spacing-36) var(--size-6);
	background-color: var(--color-light-blue-white50);
	border-radius: var(--border-radius-large);

	@media (min-width: $breakpoint-sm) {
		padding: var(--responsive-spacing-36);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}

.button {
	align-self: center;
}

.form {
	width: var(--grid-columns-12);

	@media (min-width: $breakpoint-sm) {
		width: 480px;
	}
}

.input_text {
	margin-top: var(--size-9);

	@media (min-width: $breakpoint-lg) {
		margin-top: var(--size-12);
	}
}

.info_text {
	font-size: var(--font-size-12);
}
