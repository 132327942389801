@use 'libs/theme' as *;

.container {
	@include themed-spacing;
}

.content {
	width: 100%;
	margin: 0 auto;

	.table,
	table {
		width: 100%;
	}

	&__inner {
		position: relative;
		width: 100%;
	}

	&__nowrap {
		td,
		th {
			white-space: nowrap;
		}
	}

	&__medium {
		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-10);
		}

		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-8);
		}

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-6);
		}
	}

	&__large {
		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-10);
		}

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-8);
		}
	}

	&__xl {
		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-10);
		}
	}
}

.table {
	overflow: scroll;
	background-color: var(--color-neutral-white);
	border: 1px solid var(--color-dark-blue-white75);

	@include remove-scrollbar;

	tr {
		&:not(:last-child) {
			border-bottom: 1px solid var(--color-dark-blue-white75);
		}
	}

	th {
		top: 0;
		left: 0;
		font-weight: var(--font-weight-semi-bold);
		text-align: left;
		border-right: 1px solid var(--color-dark-blue-white75);

		&:last-child {
			border-right: 0;

			&::after {
				content: none;
			}
		}

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			z-index: var(--z-index-content-highlighted);
			width: 1px;
			height: 100%;
			background-color: var(--color-dark-blue-white75);
			transform: translateX(1px);
		}
	}

	&.is-sticky th:first-child {
		position: sticky;
		z-index: calc(var(--z-index-content-highlighted) + 1);
		background-color: var(--color-dark-blue-white95);
	}

	td,
	th {
		width: var(--size-35);
		padding: var(--size-3) var(--size-4);
		font-size: var(--font-size-14);

		@media (min-width: $breakpoint-md) {
			width: unset;
		}
	}

	td:not(:last-child) {
		border-right: 1px solid var(--color-dark-blue-white75);
	}

	tbody {
		th {
			padding: var(--size-3) var(--size-4);
			background-color: var(--color-dark-blue-white95);
		}
	}

	thead {
		tr {
			background-color: var(--color-dark-blue-white95);
			border-bottom: 1px solid var(--color-dark-blue-white75);
		}

		th {
			padding: 13px 16px;
		}
	}
}

.shadow {
	@include overflow-shadow(var(--size-6), rgba(0, 0, 0, 10%), rgba(0, 0, 0, 0%));

	&::before {
		left: var(--first-col-width) !important;
	}
}

.hidden {
	visibility: hidden;
}

.scrollbar {
	height: var(--size-1);
	margin-top: var(--size-4);
	background-color: var(--color-light-blue-base);
	border-radius: var(--size-1);

	&__handle {
		width: 50%;
		height: 100%;
		background-color: var(--color-middle-blue-base);
		border-radius: var(--size-1);

		&::before {
			position: absolute;
			display: block;
			cursor: pointer;
			content: ' ';
			inset: -10px;
		}
	}
}
