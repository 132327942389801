@use 'libs/theme' as *;

.theme {
	@include themed-colors;
}

.breadcrumbs {
	display: flex;
	align-items: center;
	height: var(--size-breadcrumb);

	* {
		font-size: var(--font-size-12);
	}
}

.parent_link {
	gap: var(--size-1);

	@media (min-width: $breakpoint-sm) {
		display: none;
	}
}

.list {
	display: none;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		flex-direction: row;
		margin-right: -var(--size-2);
		padding-right: 0;
		list-style: none;
	}

	li {
		padding-right: var(--size-2);
	}

	li::after {
		display: inline;
		width: var(--size-2);
		margin-left: var(--size-2);
		color: var(--color-middle-blue-base);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-12);
		line-height: 18px;
		text-align: center;
		list-style: none;
		content: '/';
	}
}
