@use 'libs/theme' as *;

.container {
	@include themed-spacing;

	scroll-margin: 5.5rem;

	@media (min-width: $breakpoint-md) {
		scroll-margin: 12.5rem;
	}
}

.iframe {
	width: 100%;
}
