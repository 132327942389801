@use 'libs/theme' as *;

.search {
	position: relative;
}

.selected {
	background-color: var(--color-dark-blue-white95);
}

.suggestions {
	position: absolute;
	top: 105px;
	right: 0;
	left: 0;
	z-index: 3;
	background-color: #fff;
	border: 1px solid var(--color-dark-blue-white90);
	border-radius: 4px;
	box-shadow: 0 4px 16px 0 #0000001a;

	&__item {
		padding: 0.75em 1em;
		cursor: pointer;

		a {
			text-decoration: none;
		}

		&:not(:last-child) {
			border-bottom: 1px solid var(--color-dark-blue-white90);
		}
	}
}
