@use 'libs/theme' as *;

.theme {
	@include themed-spacing(96, 96);
}

.container {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
}

.mosaic {
	display: flex;
	flex-flow: column;
	gap: var(--size-4);

	@media (min-width: 960px) {
		display: grid;
		width: 100%;

		&.length__1 {
			grid-template-areas: 'card_1';
			grid-template-columns: repeat(1, 1fr);
		}

		&.length__2 {
			grid-template-areas: 'card_1 card_2';
			grid-template-columns: repeat(2, 1fr);

			&.direction__reverse {
				grid-template-areas: 'card_2 card_1';
			}
		}

		&.length__3 {
			&.layout__row {
				grid-template-areas: 'card_1 card_2 card_3';
				grid-template-columns: repeat(3, 1fr);

				&.direction__reverse {
					grid-template-areas: 'card_3 card_2 card_1';
				}

				.card {
					aspect-ratio: 3/4;
				}
			}

			&.layout__stacked {
				grid-template-areas: 'card_1 card_2' 'card_1 card_3';
				grid-template-columns: repeat(2, 1fr);

				&.direction__reverse {
					grid-template-areas: 'card_3 card_1' 'card_2 card_1';
				}
			}
		}

		@media (min-width: 960px) {
			&.layout__stacked {
				.card.index__1 {
					aspect-ratio: auto;
				}
			}

			&.layout__row {
				&.length__1 .card {
					aspect-ratio: 2/1;
				}

				&.length__2 .card {
					aspect-ratio: 8/5;
				}

				&.length__3 .card {
					aspect-ratio: 3/4;
				}
			}
		}
	}
}

.card {
	align-self: stretch; // Needed for Safari to play nice (align-items on grid doesn't work)
	width: 100%;
	aspect-ratio: 3/4;

	@media (min-width: 640px) {
		aspect-ratio: 8/5;
	}

	&.index__1 {
		grid-area: card_1;
	}

	&.index__2 {
		grid-area: card_2;
	}

	&.index__3 {
		grid-area: card_3;
	}
}
