.skeleton {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 400px;
	background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	background-size: 200% 100%;
	animation: 1.5s shine linear infinite;
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
