@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing(96, 72);
}

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	padding: 0;

	@media (min-width: $breakpoint-sm) {
		padding: 0 var(--size-8);
	}
}

.content {
	@include themed-colors;
	@include themed-spacing(96, 36, true);

	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	margin: 0;
	text-align: center;

	h2 {
		margin-bottom: var(--responsive-spacing-24);
	}
}

.header {
	width: var(--grid-columns-12);
	padding: 0 var(--size-6);

	@media (min-width: $breakpoint-sm) {
		padding: 0;
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}
