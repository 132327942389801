@use 'libs/theme' as *;

.campaignfooter {
	height: var(--size-16);
	background-color: var(--color-dark-blue-base);

	.logoContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
}
