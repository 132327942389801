@use 'libs/theme' as *;

.card {
	@include themed-spacing(0, 24, true);

	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	text-decoration: none;
	background-color: var(--color-sand-white90);
	border-radius: var(--border-radius-large);
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__has_icon {
		align-items: flex-start;
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
}
