@use 'libs/theme' as *;

.container {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-top: var(--responsive-spacing-144);

	h2 {
		margin-bottom: var(--size-4);
	}

	@media (min-width: $breakpoint-lg) {
		gap: var(--grid-columns-1);
	}

	&__text {
		width: var(--grid-columns-12);
		margin-bottom: var(--responsive-spacing-72);

		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-10);
		}

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-6);
		}

		.subtitle {
			margin-bottom: var(--responsive-spacing-48);
			color: var(--color-primary);
			font-weight: var(--font-weight-semi-bold);
		}

		.phoneNumber {
			margin-bottom: var(--size-4);
			color: var(--color-secondary);
			text-decoration: none;
		}

		.rte {
			@include multiplier {
				a {
					color: var(--color-middle-blue-dark-blue20);
					text-decoration: none;
				}
			}
		}
	}

	&__img {
		position: absolute;
		top: calc(var(--size-9) * -1);
		right: var(--size-10);
		bottom: 0;
		display: none;
		width: var(--grid-columns-5);

		@media (min-width: $breakpoint-md) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		img {
			width: auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
}
