@use 'libs/theme' as *;

.container {
	@include themed-spacing;
}

.iframe,
.medium {
	display: block;
	width: var(--grid-columns-12);
	margin: auto;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}

	&.large {
		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-10);
		}

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-8);
		}
	}

	&.xl {
		width: var(--grid-columns-12);

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-10);
		}
	}

	&.xxl {
		width: var(--grid-columns-12);
	}
}

[class*='content-drawer'] {
	.container {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}
