@use 'libs/theme' as *;

.theme {
	@include themed-colors;

	padding: 0;
	background-color: var(--palette-primary);

	&__color {
		padding: var(--responsive-spacing-96) 0;
	}
}

.line {
	position: relative;
	width: 100%;
	height: 1px;
	margin: 0;
}

.line::before {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--palette-secondary);
	opacity: 0.2;
	content: '';
}
