@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing;
}

.content {
	display: grid;
	gap: var(--size-2);

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: repeat(2, 1fr);
		gap: var(--size-4);
	}

	@media (min-width: $breakpoint-md) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: $breakpoint-lg) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.country {
	display: flex;
	gap: var(--size-4);
	align-items: center;
	padding: var(--size-6);
	font-weight: var(--font-weight-semi-bold);
	text-decoration: none;
	background-color: var(--color-dark-blue-white95);
	border-radius: var(--border-radius-large);

	@media (min-width: $breakpoint-sm) {
		flex-direction: column;
		padding: var(--size-8);
	}
}

.flag {
	position: relative;
	aspect-ratio: 7 / 5;
	width: 42px;
	overflow: hidden;
	border-radius: var(--border-radius-medium);

	@media (min-width: $breakpoint-sm) {
		width: 84px;
	}
}
