@use 'libs/theme' as *;

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
}

.form {
	display: flex;
	flex-direction: column;
	gap: var(--size-9);
	width: var(--grid-columns-12);

	@media (min-width: $breakpoint-md) {
		flex-direction: row;
		gap: var(--size-4);
	}

	&__inputs {
		width: 100%;

		@media (min-width: $breakpoint-md) {
			width: 50%;
		}
	}
}
