.image {
	width: 100%;
	height: 100%;

	span {
		height: 100% !important;
		min-height: 100% !important;
	}
}

.initialHeight {
	height: initial;
}
