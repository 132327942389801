@use 'libs/theme' as *;

.block {
	display: block;

	> * {
		*:not(p, li, a) {
			&:not(:first-child) {
				margin-top: var(--size-fluid-medium);
			}
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		+ ol,
		+ ul {
			margin-top: var(--size-2);
		}
	}

	sup {
		line-height: 0;
	}

	em {
		font-style: italic;
	}

	small {
		font-size: var(--font-size-xsmall);
	}

	strong {
		font-weight: var(--font-weight-semi-bold);
	}

	ul,
	ol {
		display: flex;
		flex-flow: column nowrap;
		margin: var(--size-4) 0;
		padding: 0.5rem 0 0.5rem 1rem;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	hr {
		border-bottom: 1px solid;
	}

	.center {
		@include multiplier(10) {
			text-align: center;
		}
	}

	blockquote {
		padding: var(--size-6);
		font-size: var(--font-size-h5);
		font-style: italic;
		text-align: center;
	}

	pre {
		white-space: pre-wrap;
	}

	table,
	th,
	td {
		border: 1px solid black;
	}

	th,
	td {
		padding: var(--size-fluid-small);
	}
}
