@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	width: var(--grid-columns-12);

	@include themed-spacing(96, 36, false);

	@media (min-width: $breakpoint-md) {
		flex-direction: row;
		gap: (1 / 12) * 100%;
	}

	h2 {
		width: var(--grid-columns-12);

		@media (min-width: $breakpoint-md) {
			flex-direction: row;
			width: var(--grid-columns-4);
		}
	}

	h3 {
		margin-bottom: var(--size-4);
	}

	&__steps_container {
		width: var(--grid-columns-12);
		margin-top: var(--size-12);

		@media (min-width: $breakpoint-md) {
			flex-direction: row;
			width: (7 / 12) * 100%;
			margin-top: 0;
		}

		.step {
			display: flex;

			&__number_container {
				display: flex;
				flex-direction: column;
				margin-right: var(--size-6);

				@media (min-width: $breakpoint-lg) {
					margin-right: var(--size-8);
				}

				.number {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 48px;
					height: 48px;
					background-color: var(--color-signal-green70);
					border-radius: 80px;

					@media (min-width: $breakpoint-sm) {
						width: 64px;
						height: 64px;
					}

					@media (min-width: $breakpoint-lg) {
						width: 80px;
						height: 80px;
					}

					p {
						margin-bottom: 0;
					}
				}

				&__number_line {
					flex: 1;
					width: 0;
					margin: var(--size-1) auto;
					border: 1px solid var(--color-signal-green70);
				}
			}

			.content {
				padding-top: var(--size-3);
				padding-bottom: var(--size-12);

				@media (min-width: $breakpoint-sm) {
					padding-top: 18px;
					padding-bottom: var(--size-12);
				}

				@media (min-width: $breakpoint-md) {
					padding-bottom: var(--size-12);
				}

				@media (min-width: $breakpoint-lg) {
					padding-top: 25px;
					padding-bottom: var(--size-16);
				}
			}

			&:last-of-type {
				.step__number_container__number_line {
					display: none !important;
				}

				.content {
					padding-bottom: 0;
				}
			}
		}
	}

	&__button_wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-top: var(--size-8);
		row-gap: var(--size-6);
	}
}
