@use 'libs/theme' as *;

.icon_wrapper {
	--button-icon-color: var(--color-neutral-white);

	padding: var(--size-2);
	border: 2px solid transparent;
	border-radius: var(--border-radius-round);
	cursor: pointer;
	transition: all 0.2 ease-in-out, border-color 0s;

	&__pressed,
	&:active {
		z-index: var(--z-index-content-highlighted);
		border: 2px solid var(--color-secondary);
	}

	@include multiplier {
		&__filled,
		&:active {
			box-shadow: none;
		}
	}

	&__filled {
		background-color: var(--color-middle-blue-base);
	}
}
