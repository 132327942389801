@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing;
}

.content {
	width: var(--grid-columns-12);

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
		margin: 0 auto;
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}

[class*='content-drawer'] {
	.theme {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.content {
		width: var(--grid-columns-12);
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}

.article_margin {
	margin-top: var(--responsive-spacing-48);
}
