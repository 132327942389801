@use 'libs/theme' as *;

.container {
	// Styling for container if needed
	display: flex;
	flex-direction: column;

	// make up for margin of content block
	margin-bottom: calc(-1 * var(--size-12));

	@media (min-width: $breakpoint-lg) {
		margin-bottom: calc(-1 * var(--size-16));
	}
}

.tabs {
	display: flex;
	flex-direction: row;
}

.content {
	// Grid-row helpers
	width: var(--grid-rows-12);

	h2 {
		// Media-query optimized margin
		margin-bottom: var(--responsive-spacing-24);
	}

	// Theming for inner block container
	// where block needs space around it
	@include themed-colors;
	@include themed-spacing(96, 36, true);
}
