@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing(96, 72);
}

.form_container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	padding: 0;

	@media (min-width: $breakpoint-sm) {
		padding: 0 var(--size-8);
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	align-items: center;
	width: var(--grid-columns-12);
	padding: 0 var(--size-6);

	@media (min-width: $breakpoint-sm) {
		padding: 0;
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}

.form_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
}

.form_inputs {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);
	max-width: 480px;
}

.confirmation {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);

	&__header {
		display: flex;
		flex-direction: column;
		gap: var(--size-4);
		align-items: center;
		justify-content: center;
		width: var(--grid-columns-12);
		text-align: center;

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-8);
		}
	}

	&__subtexts {
		display: flex;
		flex-direction: column;
		gap: var(--size-6);
		width: var(--grid-columns-12);

		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-8);
		}

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-6);
		}

		p {
			display: flex;
			flex-direction: row;
			gap: var(--size-3);

			svg {
				min-width: var(--size-6);
			}
		}
	}
}

.banner_wrapper {
	max-width: 480px;
}
