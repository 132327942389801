@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing;
}

.container {
	display: flex;
}

.offices_list {
	display: flex;
	flex-direction: column;
	gap: var(--size-12);
	width: 100%;
}

.tab_content {
	width: 100%;
	overflow: hidden;
	background-color: var(--color-light-blue-white75);
	border-radius: var(--border-radius-large);
}

.office {
	display: none;

	&.active {
		display: block;

		@media (min-width: $breakpoint-sm) {
			display: flex;
			flex-direction: row;
		}
	}
}

.map_container {
	position: relative;
	aspect-ratio: 3/2;

	@media (min-width: $breakpoint-sm) {
		width: 50%;
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	align-self: center;
	padding: var(--responsive-spacing-36);

	@media (min-width: $breakpoint-sm) {
		width: 50%;
	}

	&_title {
		@include h4;
	}

	h3,
	a {
		font-weight: var(--font-weight-semi-bold);
	}

	p,
	h3,
	a {
		font-size: var(--font-size-14);
	}
}

.hours {
	white-space: pre-wrap;
}

.address {
	a {
		color: var(--color-secondary);
		font-size: var(--font-size-16);
		text-decoration: none;
	}
}
