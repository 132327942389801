@use 'libs/theme' as *;

.navigation_card {
	position: absolute;
	top: 100%;
	right: 0;
	z-index: var(--z-index-content-highlighted);
	background-color: var(--color-primary);
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 20%);

	@media (max-width: $breakpoint-xs-max) {
		left: 0;
	}

	@media (min-width: $breakpoint-sm) {
		width: 320px;
		height: auto !important; // Ignore height set by useAdjustedDropdownHeight on larger screens
		margin-top: var(--size-5);
		border-radius: var(--border-radius-large);

		&__menu {
			right: -12px;
		}

		&__account {
			right: 84px;
		}

		&__search {
			right: 36px;
		}
	}

	a {
		color: var(--color-neutral-white);
	}
}

.header {
	margin-top: 0;
	padding-bottom: var(--size-6);
	color: var(--color-secondary);
	font-weight: var(--font-weight-semi-bold);
	text-transform: uppercase;
}

.shortcuts {
	padding-top: var(--size-12);

	li {
		a,
		button {
			margin: var(--size-5) 0;
			padding: 0;
		}

		&:last-child {
			a,
			button {
				margin-bottom: 0;
			}
		}
	}
}

.shortcuts__title {
	margin-bottom: var(--size-5);
	color: var(--color-secondary);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-14);
	text-transform: uppercase;
}

/* Account dropdown */
.log_in_button {
	width: 100%;
}

.not_logged_in_info {
	padding-top: var(--size-4);
	color: var(--color-neutral-white);
	font-size: var(--font-size-14);
}

/* Search */
.input_field {
	input,
	input:focus {
		padding-top: var(--size-4);
		padding-bottom: var(--size-4);
		padding-left: var(--size-6);
		background-color: var(--color-neutral-white);
		border-bottom: 0;
		border-radius: var(--border-radius-medium);

		&::placeholder {
			color: var(--color-primary);
		}
	}

	svg {
		color: var(--color-primary);
	}
}

.scroll {
	padding: var(--size-12) var(--size-6);

	@media (max-width: $breakpoint-xs-max) {
		height: 100%;
		overflow-y: scroll;
	}
}

.dismiss {
	position: absolute;
	top: var(--size-6);
	right: var(--size-6);
	padding: 0;
	font-size: inherit;
	background: none;

	&__icon {
		cursor: pointer;
	}
}
