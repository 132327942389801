@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing(96, 72);

	background-color: var(--color-dark-blue-white95);
}

.container {
	display: flex;
	width: var(--grid-rows-12);
}

.content {
	@include themed-colors;
	@include themed-spacing(96, 36, true);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);

	h2 {
		margin-bottom: var(--responsive-spacing-48);
	}
}

.persons_list {
	display: flex;
	flex-direction: column;
	grid-auto-rows: 1fr;
	gap: var(--size-4);
	align-items: center;
	width: var(--grid-columns-12);
	margin: initial auto;

	@media (min-width: $breakpoint-sm) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: unset;
		max-width: 664px;
	}

	@media (min-width: $breakpoint-md) {
		grid-template-columns: repeat(3, 1fr);
		max-width: 1004px;
	}

	@media (min-width: $breakpoint-lg) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
		max-width: unset;
	}

	&__loader {
		width: var(--grid-columns-12);
	}
}
