@use 'libs/theme' as *;

.relative {
	position: relative;
	z-index: calc(var(--z-index-header) - 1);
}

.wrapper {
	@include themed-colors;

	top: var(--size-header);
	width: 100%;
	box-shadow: 0 1px 0 rgba(255, 255, 255, 20%);

	@media (min-width: $breakpoint-md) {
		top: var(--size-header-md);
	}
}

.container {
	--spacing-offset: var(--size-6);

	@media (min-width: $breakpoint-sm) {
		--spacing-offset: var(--size-8);
	}

	@media (min-width: $breakpoint-md) {
		--spacing-offset: var(--size-10);
	}

	@media (min-width: $breakpoint-lg) {
		--spacing-offset: var(--size-12);
	}

	padding-top: var(--size-6);
	padding-bottom: var(--size-3);

	@media (min-width: $breakpoint-md) {
		top: var(--size-header-md);
	}

	@media (min-width: $breakpoint-lg) {
		padding-top: var(--size-9);
	}

	h1 {
		margin-bottom: 0.5em;
		color: var(--color-secondary);
		font-size: var(--font-size-16);
		line-height: 1.25;
		text-transform: uppercase;

		@media (min-width: $breakpoint-sm) {
			font-size: 20px; // TODO: Remove this line when we have a font-size-20
		}

		@media (min-width: $breakpoint-md) {
			font-size: var(--font-size-24);
		}
	}

	.nav {
		position: relative;

		ul {
			display: flex;
			flex-wrap: nowrap;
			gap: var(--size-6);
			margin: 0 calc(var(--spacing-offset) * -1);
			padding: 0 var(--spacing-offset);
			overflow-x: scroll;

			.menu_link_wrapper {
				display: flex;
				gap: var(--size-1);
			}

			@include remove-scrollbar;

			.active {
				border-bottom: 2px solid var(--color-secondary);
			}

			a {
				display: block;
				padding: var(--size-2) 0 calc(var(--size-2) - 2px) !important;
				font-weight: var(--font-weight-semi-bold);
				white-space: nowrap;
				text-decoration: none;
			}

			.notification {
				--notification-size: 18px;
				--notification-border-radius: 9px;

				display: flex;
				align-items: center;
				justify-content: center;
				min-width: var(--notification-size);
				height: fit-content;
				margin-top: var(--size-1);
				padding: 0 var(--size-1);
				color: var(--palette-darkblue-primary);
				font-weight: var(--font-weight-semi-bold);
				font-size: var(--font-size-12);
				background-color: var(--color-signal-yellow);
				border-radius: var(--notification-border-radius);
			}
		}
	}

	.shadow {
		position: absolute;
		inset: 0 calc(var(--spacing-offset) * -1);
		top: auto;
		height: 100%;
		pointer-events: none;

		&::before,
		&::after {
			position: absolute;
			bottom: 0;
			display: block;
			width: calc(var(--spacing-offset) * 2);
			height: 100%;
			opacity: 0;
			transition: opacity 200ms ease;
			content: ' ';
		}

		&::before {
			left: 0;
			background: var(--color-scroll-shadow-gradient-left);
		}

		&:not(.scroll_start)::before {
			opacity: 1;
		}

		&::after {
			right: 0;
			background: var(--color-scroll-shadow-gradient-right);
		}

		&:not(.scroll_end)::after {
			opacity: 1;
		}
	}
}
