@use 'libs/theme/src/lib/sass/global/breakpoints.scss' as *;

@mixin h0 {
	font-weight: var(--font-weight-extra-bold);
	font-size: 44px;
	font-family: var(--font-family);
	line-height: 1;

	@media (min-width: $breakpoint-sm) {
		font-size: 58px;
	}

	@media (min-width: $breakpoint-lg) {
		font-size: 72px;
	}
}

@mixin h1 {
	font-weight: var(--font-weight-extra-bold);
	font-size: 36px;
	font-family: var(--font-family);
	line-height: 1;

	@media (min-width: $breakpoint-sm) {
		font-size: 46px;
	}

	@media (min-width: $breakpoint-lg) {
		font-size: 56px;
	}
}

@mixin h2 {
	font-weight: var(--font-weight-extra-bold);
	font-size: 28px;
	font-family: var(--font-family);
	line-height: 1.25;

	@media (min-width: $breakpoint-sm) {
		font-size: 34px;
	}

	@media (min-width: $breakpoint-lg) {
		font-size: 40px;
	}
}

@mixin h3 {
	font-weight: var(--font-weight-extra-bold);
	font-size: 24px;
	font-family: var(--font-family);
	line-height: 1.25;

	@media (min-width: $breakpoint-sm) {
		font-size: 28px;
	}

	@media (min-width: $breakpoint-lg) {
		font-size: 32px;
	}
}

@mixin h4 {
	font-weight: var(--font-weight-extra-bold);
	font-size: 20px;
	font-family: var(--font-family);
	line-height: 1.25;

	@media (min-width: $breakpoint-sm) {
		font-size: 22px;
	}

	@media (min-width: $breakpoint-lg) {
		font-size: 24px;
	}
}

@mixin h5 {
	font-weight: var(--font-weight-extra-bold);
	font-size: var(--font-size-20);
	font-family: var(--font-family);
	line-height: 1.25;
}

@mixin nav {
	margin-top: 0.7em;
	margin-bottom: 0.4em;
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
	font-family: var(--font-family);
}

@mixin text20 {
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-20);
	font-family: var(--font-family);
}

/**
 * @mixin get-font-size
 * Calculates the font-size based on the container width.
 *
 * Prerequisite: `container-type: inline-size;` must be set on the container.
 *
 * @param {number} $containerWidth - The width of the container
 * @param {number} $fontSize - The font-size
 */
@mixin get-font-size($containerWidth, $fontSize) {
	font-size: calc($fontSize / calc($containerWidth / 100) * 1cqw);
}
