@use 'libs/theme' as *;

.container {
	width: var(--grid-columns-12);
	text-align: center;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}

	@include themed-spacing(72, 0, false);

	h2 {
		margin-bottom: var(--size-4);
	}
}

.img_container {
	margin: auto;
	margin-bottom: var(--responsive-spacing-24);
}

.subheading_container {
	margin-bottom: var(--responsive-spacing-48);
	font-size: var(--font-size-16);
}

.subheading {
	font-size: var(--font-size-16);
}

.link_container {
	display: flex;
	gap: var(--size-6);
	justify-content: center;
	margin-bottom: var(--responsive-spacing-24);
}

.paragraph {
	@include multiplier {
		a {
			color: var(--color-secondary);
			font-weight: var(--font-weight-regular);
			text-decoration: none;
		}
	}
}
