@use 'libs/theme' as *;

.theme {
	@include themed-colors;
}

.container {
	@include themed-spacing;

	h2 {
		margin-bottom: var(--size-4);
	}
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: var(--size-6);
	margin: 0 auto;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-md) {
		flex-direction: row;
		gap: var(--grid-columns-1);
		width: var(--grid-columns-12);
	}

	&_text_first {
		@media (min-width: $breakpoint-md) {
			flex-direction: row-reverse;

			.text__content {
				align-items: flex-start;
			}
		}
	}

	&_text_last {
		@media (min-width: $breakpoint-md) {
			flex-direction: row;
		}
	}

	img {
		width: 100%;
		height: 100%;
		border-radius: var(--border-radius-large);
	}

	video {
		width: 100%;
		border-radius: var(--border-radius-large);
	}
}

.media_container {
	width: var(--grid-columns-12);

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-6);
	}
}

.text_content {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	width: var(--grid-columns-12);

	&__inner {
		width: var(--grid-columns-12);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-6);
	}
}

.button_wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: var(--size-8);
	row-gap: var(--size-6);
}
