@use 'libs/theme' as *;

.container {
	aspect-ratio: 3 / 4;
}

.content {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	text-decoration: none;
	background-color: var(--color-sand-white75);
	border-radius: var(--border-radius-large);

	&__image_container {
		height: 50%;
	}

	&__text_container {
		height: 100%;
		padding: var(--size-6);
		background-color: var(--color-sand-white75);

		.info_line {
			display: flex;
			justify-content: space-between;
			margin-bottom: var(--size-4);
			line-height: var(--line-height-default);
		}

		.tag {
			font-weight: var(--font-weight-semi-bold);
			font-size: var(--font-size-12);
			text-transform: uppercase;
		}

		.date {
			font-weight: var(--font-weight-regular);
			font-size: var(--font-size-12);
		}
	}

	&__has_img .content__text_container {
		height: 50%;
		background-color: var(--color-neutral-white);
	}

	.headline__container {
		container-type: inline-size;
	}

	&__headline {
		display: -webkit-box;
		overflow: hidden;
		font-weight: var(--font-weight-semi-bold);
		-webkit-line-clamp: 5;
		line-clamp: 5;
		-webkit-box-orient: vertical;

		@include get-font-size(276, 20);
	}
}

.extended {
	@include multiplier {
		@media (min-width: $breakpoint-sm) {
			min-height: auto;
			aspect-ratio: auto;

			.content {
				flex-direction: row;
				width: var(--grid-columns-12);
				margin: auto;

				&__image_container {
					aspect-ratio: 4 / 3;
					min-width: 50%;
				}

				&__text_container {
					min-width: 50%;
					height: auto;
					aspect-ratio: auto;
				}
			}
		}

		@media (min-width: $breakpoint-md) {
			.content {
				width: var(--grid-columns-10);
			}
		}

		@media (min-width: $breakpoint-lg) {
			.content {
				width: var(--grid-columns-8);
			}
		}
	}
}
