@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing;
}

.content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: auto;

	img,
	video {
		width: 100%;
		border-radius: var(--border-radius-large);
	}

	&__medium {
		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-8);
		}

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-6);
		}
	}

	&__large {
		@media (min-width: $breakpoint-sm) {
			width: var(--grid-columns-10);
		}

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-8);
		}
	}

	&__xl {
		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-10);
		}
	}
}

[class*='content-drawer'] {
	.theme {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.content {
		width: var(--grid-columns-12);
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}
