@use 'libs/theme' as *;

.container {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: flex-start;
	margin: var(--responsive-spacing-96) var(--size-6);
	padding: 0;
	background-color: var(--palette-primary);
	border-top: 1px solid var(--dark-blue-25, rgba(0, 32, 57, 0.25));
	border-bottom: 1px solid var(--dark-blue-25, rgba(0, 32, 57, 0.25));
}

.content {
	display: flex;
	flex-direction: column;
	padding: var(--responsive-spacing-48) 0;

	form {
		display: flex;
		flex-direction: column;
		gap: var(--responsive-spacing-36);
		align-items: flex-start;
	}

	h4 {
		margin-bottom: var(--size-4);
	}

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}

.question {
	p {
		margin-bottom: var(--size-8);
	}
}

.radio_group {
	div {
		display: flex;
		flex-direction: row;
		gap: var(--size-2);
	}

	label {
		padding: 12px 20px;
		border: 2px solid #000;
		border-radius: 4px;
	}
}

.loader_wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	width: 100%;
	padding: 0 var(--size-6);
}

.link {
	color: var(--color-middle-blue-dark-blue20);
	font-weight: var(--font-weight-semi-bold);

	a {
		text-decoration: none;
	}
}
