@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;

	@media (min-width: $breakpoint-sm) {
		padding: 0 var(--size-8);
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	margin-bottom: var(--responsive-spacing-48);
	padding: 0 var(--size-6);

	@media (min-width: $breakpoint-sm) {
		padding: 0;
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}
