@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0;
	overflow: hidden;
	background-color: var(--color-light-blue-white75);
	border-radius: var(--size-2);

	@media (min-width: $breakpoint-md) {
		flex-direction: row-reverse;
		align-items: center;
	}
}

.phoneNumber {
	color: var(--color-middle-blue-base);
}

.button_container {
	display: flex;
	flex-direction: column;
	gap: var(--size-6);
	width: fit-content;
	margin-top: var(--size-8);

	@media (min-width: $breakpoint-sm) {
		flex-direction: row;
		gap: var(--size-4);
	}
}

.text_container {
	padding: var(--responsive-spacing-36);

	@media (min-width: $breakpoint-md) {
		width: 50%;
	}
}

.link {
	@include multiplier {
		a {
			color: var(--color-middle-blue-dark-blue20);
			font-weight: var(--font-weight-semi-bold);
			text-decoration: none;
		}
	}
}

.img_container {
	position: relative;
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	aspect-ratio: 3/2;

	@media (min-width: $breakpoint-md) {
		width: 50%;
	}
}

.header {
	margin-bottom: var(--size-4);
}
