.loader {
	--dot-size: 6px;
	--gap-size: var(--size-2);
	--animation-duration: 0.5s;

	&.large {
		--dot-size: var(--size-3);
		--gap-size: var(--size-3);
	}

	display: flex;
	gap: var(--gap-size);

	&::before {
		transform-origin: calc(var(--dot-size) + var(--gap-size) / 2) calc(var(--dot-size) / 2);
		animation: arch var(--animation-duration) linear infinite;
	}

	&::after {
		animation: slide var(--animation-duration) linear infinite;
	}

	&::before,
	&::after {
		width: var(--dot-size);
		height: var(--dot-size);
		background-color: var(--color-middle-blue-base);
		border-radius: var(--dot-size);
		content: '';
	}
}

@keyframes arch {
	0% {
		transform: rotate(0deg);
	}

	80%,
	100% {
		transform: rotate(180deg);
	}
}

@keyframes slide {
	0% {
		transform: translateX(0);
	}

	80%,
	100% {
		transform: translateX(calc(-1 * (var(--dot-size) + var(--gap-size))));
	}
}
