@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	padding: var(--size-6);
	overflow: hidden;
	text-decoration: none;
	background-color: var(--color-neutral-white);
	border-radius: var(--border-radius-large);

	@media (min-width: $breakpoint-md) {
		flex-direction: row;
		gap: 0;
		padding: var(--responsive-spacing-24) var(--responsive-spacing-24) var(--responsive-spacing-24)
			var(--responsive-spacing-36);
	}
}

.date {
	@include h2;

	margin-bottom: var(--size-6);
	color: var(--color-middle-blue-base);
	line-height: 1.25;
	white-space: nowrap;

	p {
		margin: 0;
	}

	&__day_count {
		color: var(--color-neutral-black);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
	}

	@media (min-width: $breakpoint-md) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-3);
	}

	@media (min-width: $breakpoint-lg) {
		align-self: center;
		justify-self: center;
		margin-bottom: 0;
	}
}

.details {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: var(--size-6);
	align-items: flex-start;

	@media (min-width: $breakpoint-md) {
		justify-content: space-between;
		width: var(--grid-columns-9);
	}

	@media (min-width: $breakpoint-lg) {
		flex-direction: row;
		width: var(--grid-columns-6);
	}

	&__info {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: var(--size-6);

		.title {
			@include h4;

			font-weight: var(--font-weight-semi-bold);
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: var(--size-6);

		@media (min-width: $breakpoint-md) {
			align-self: center;
		}
	}

	&__label {
		@media (min-width: $breakpoint-lg) {
			display: none;
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: var(--size-2);

	&__item {
		display: flex;
		gap: var(--size-2);
		font-size: var(--font-size-16);

		&__icon_online {
			color: var(--color-middle-blue-base);
		}
	}
}

.label_wrapper {
	display: none;

	@media (min-width: $breakpoint-lg) {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		width: var(--grid-columns-3);
	}
}

/*
 * Classnames derived from the event status config key, therefore not following SCSS naming conventions. 
*/
.label {
	padding: var(--size-2) var(--size-4);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
	border-radius: var(--border-radius-medium);

	--color-signal-red-linear-gradient: linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
		#bf2626;

	&__invite-only {
		color: var(--color-dark-blue-base);
		background: var(--color-light-blue-base);
	}

	&__invited-user {
		color: var(--color-dark-blue-base);
		background: var(--color-light-blue-base);
	}

	&__not-invited-user {
		color: var(--color-dark-blue-base);
		background: var(--color-light-blue-base);
	}

	&__not-logged-in-invitation-required {
		color: var(--color-dark-blue-base);
		background: var(--color-light-blue-base);
	}

	&__registered-user {
		color: var(--color-primary);
		background: var(--palette-sand-primary);
	}

	&__no-more-seats {
		color: var(--color-primary);
		background: var(--color-signal-red-linear-gradient);
	}

	&__deadline-passed {
		color: var(--color-primary);
		background: var(--color-signal-red-linear-gradient);
	}

	&__has-taken-place {
		color: var(--color-neutral-white);
		background: var(--color-dark-blue-base);
	}

	&__cancelled {
		color: var(--color-neutral-white);
		background: var(--color-signal-red);
	}
}
