@use 'libs/theme' as *;

.container {
	--border: 1px solid var(--color-dark-blue-white90);

	@media (min-width: $breakpoint-md) {
		display: flex;
		gap: var(--grid-columns-1);
	}

	h2 {
		margin-bottom: var(--size-12);

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-5);
			margin-bottom: 0;
		}
	}
}

.list {
	flex-shrink: 0;
	border-bottom: var(--border);

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-6);
	}
}

.item {
	position: relative;
	border-top: var(--border);

	> a {
		display: block;
		width: 100%;
		padding: var(--size-5) var(--size-10) var(--size-5) 0 !important;
		font-weight: var(--font-weight-semi-bold);
		text-decoration: none;
	}
}

.icon {
	position: absolute;
	top: var(--size-5);
	right: 0;
	pointer-events: none;
}
