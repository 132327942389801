@use 'libs/theme' as *;

@mixin linear-gradient($direction: top) {
	background: linear-gradient(
		to $direction,
		hsla(206deg, 100%, 11%, 0.5) 50%,
		hsla(206deg, 100%, 11%, 0.4) 60%,
		hsla(206deg, 100%, 11%, 0.3) 70%,
		hsla(206deg, 100%, 11%, 0.2) 80%,
		hsla(206deg, 100%, 11%, 0.1) 90%,
		hsla(206deg, 100%, 11%, 0) 100%
	);
}

.card {
	@include themed-colors;

	display: flex;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	text-decoration: none;
	border-radius: var(--size-2);
	-webkit-text-stroke-color: var(--palette-secondary);

	.illustration {
		position: relative;

		> span {
			position: absolute;
			display: block;
			inset: var(--responsive-spacing-24);
			background-color: currentcolor;
			mask-size: contain;
			mask-position: top left;
			mask-repeat: no-repeat;
		}
	}

	.text {
		width: 100%; // Needed for mobile Safari to play nice
		padding: var(--responsive-spacing-24);

		&__inner {
			container-type: inline-size;
		}
	}

	.subject {
		margin-bottom: var(--size-4);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-14);
		text-transform: uppercase;
	}

	.title {
		flex-grow: 1;
		font-weight: var(--font-weight-semi-bold);
		line-height: 1.25;
	}

	.value {
		margin-bottom: var(--size-4);
		color: transparent;
		font-weight: var(--font-weight-semi-bold);
		line-height: 1;
		-webkit-text-stroke-width: 2px;
	}

	&.mosaicCardHalfImage,
	&.mosaicCardIllustration {
		flex-direction: column;

		&.direction__reverse {
			flex-direction: column-reverse;
		}

		.image {
			height: auto;
		}

		.image,
		.illustration,
		.text {
			flex-grow: 1;
			aspect-ratio: 3 / 2;
		}

		.image,
		.illustration {
			overflow: hidden;
		}

		.title {
			font-size: calc((24 / 3.2) * 1cqw);
		}

		@media (min-width: 640px) {
			flex-direction: row;

			&.direction__reverse {
				flex-direction: row-reverse;
			}

			.image,
			.illustration,
			.text {
				width: 50%;
			}

			.title {
				font-size: (24 / 2.28) * 1cqw;
			}
		}

		@media (min-width: 960px) {
			&.length__1 {
				.image,
				.illustration {
					width: (2 / 3) * 100%;
				}

				.text {
					width: (1 / 3) * 100%;
				}

				.title {
					font-size: (36 / 3.3067) * 1cqw;
				}
			}

			&.length__3 {
				&.layout__row,
				&.layout__stacked.index__1 {
					flex-direction: column;

					&.direction__reverse {
						flex-direction: column-reverse;
					}

					.image,
					.illustration,
					.text {
						width: 100%;
					}

					.title {
						font-size: (24 / 3.2) * 1cqw;
					}
				}
			}
		}
	}

	&.mosaicCardFullImage,
	&.mosaicCardNoImage,
	&.mosaicCardValue {
		flex-direction: column-reverse;

		&.direction__reverse {
			flex-direction: column;
		}

		.title {
			font-size: (28 / 3.2) * 1cqw;
		}

		.value {
			font-size: (28 / 3.2) * 2.5cqw;
		}

		@media (min-width: 640px) {
			.title {
				font-size: (28 / 5.2) * 1cqw;
			}

			.value {
				font-size: (28 / 5.2) * 2.5cqw;
			}
		}

		@media (min-width: 960px) {
			&.length__1 {
				.title {
					font-size: (56 / 11.2) * 1cqw;
				}

				.value {
					font-size: (56 / 11.2) * 2.5cqw;
				}
			}

			&.length__3 {
				&.layout__row,
				&.layout__stacked .index__1 {
					.title {
						font-size: (28 / 3.2) * 1cqw;
					}

					.value {
						font-size: (28 / 3.2) * 2.5cqw;
					}
				}
			}
		}
	}

	@media (min-width: 960px) {
		&.layout__row.length__1.direction__reverse {
			.illustration span {
				mask-position: top right;
			}
		}
	}

	&.mosaicCardFullImage {
		position: relative;
		color: var(--color-neutral-white);

		.image {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		.text {
			position: relative;

			@include linear-gradient(top);
		}

		&.direction__reverse {
			.text {
				@include linear-gradient(bottom);
			}
		}
	}
}
