@use 'libs/theme' as *;

.modal_content {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);
	width: var(--grid-columns-12);
	text-align: center;

	h2 {
		@include h3;

		font-weight: var(--font-weight-semi-bold);
	}

	button {
		align-self: center;
	}
}

.info {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.info_item {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	padding: var(--responsive-spacing-24) 0 var(--responsive-spacing-24) 0;

	@media (min-width: $breakpoint-md) {
		grid-template-columns: 1fr 1fr auto;
	}

	&__title {
		margin-bottom: var(--size-3);
		font-weight: var(--font-weight-semi-bold);
	}

	&__details {
		display: flex;
		flex-direction: column;
		grid-area: 2 /1;
		gap: var(--size-2);

		@media (min-width: $breakpoint-md) {
			grid-area: initial;
			justify-content: space-between;
		}
	}

	&__link {
		display: flex;
		color: var(--color-middle-blue-base);
		font-weight: var(--font-weight-semi-bold);
		text-decoration: none;
	}

	address {
		font-size: var(--font-size-16);
	}

	p {
		font-size: var(--font-size-16);
	}
}

.info_item:first-child {
	border-color: rgba(0, 32, 57, 0.1);
	border-style: solid;
	border-width: 1px 0;
}

.info_item:last-child {
	border-bottom: 1px solid rgba(0, 32, 57, 0.1);
}
