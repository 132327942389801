@use 'libs/theme' as *;

.menu_view,
.subjects,
.primary {
	a,
	button {
		padding: 0;
	}

	li {
		margin-bottom: var(--size-5);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.subjects {
	margin-bottom: var(--size-12);

	@media (min-width: $breakpoint-sm) {
		display: none;
	}
}

.primary {
	margin-bottom: var(--size-12);

	@media (min-width: $breakpoint-md) {
		display: none;
	}

	a {
		font-weight: var(--font-weight-semi-bold);
		text-transform: uppercase;
		text-decoration: none;
	}
}

.info {
	@media (max-width: $breakpoint-sm-max) {
		padding-top: var(--size-12);
		border-top: 1px solid rgba(255, 255, 255, 20%);
	}
}
