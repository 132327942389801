@use 'libs/theme' as *;

.footer {
	margin-top: auto;
	color: var(--color-neutral-white);
	background-color: var(--color-dark-blue-base);

	> * {
		display: block;

		@media (min-width: $breakpoint-sm) {
			display: flex;
		}

		> * {
			flex: 1;
		}
	}
}

.uppercase {
	a {
		font-weight: var(--font-weight-semi-bold);
		text-transform: uppercase;
	}
}

.top_menu {
	padding-top: var(--size-18);
	padding-bottom: var(--size-15);

	@media (min-width: $breakpoint-lg) {
		padding-top: var(--size-24);
		padding-bottom: var(--size-21);
	}

	@include multiplier {
		a {
			padding-top: var(--size-3);
			padding-bottom: var(--size-3);
			font-size: var(--font-size-16);
		}
	}
}

.subject {
	&__title {
		display: flex;
		align-items: center;

		@include multiplier {
			a {
				padding: 0;
				padding-right: var(--size-1);
			}
		}
	}

	&__items {
		margin-top: var(--size-3);
		column-count: 1;

		@media (min-width: $breakpoint-md) {
			column-count: 2;
		}

		li {
			padding-right: var(--size-6);

			&:last-child a {
				@media (max-width: $breakpoint-sm-max) {
					padding-bottom: 0;
				}
			}
		}

		svg {
			margin-top: -3px;
		}

		@include multiplier {
			a {
				padding-left: var(--size-2);
			}
		}
	}
}

.split_menu {
	display: block;

	@media (min-width: $breakpoint-md) and (max-width: $breakpoint-md-max) {
		flex: 0.5;
	}

	@media (min-width: $breakpoint-lg) {
		display: flex;
	}

	@media (max-width: $breakpoint-xs-max) {
		nav {
			padding: var(--size-12) 0;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	nav:last-child {
		margin-bottom: var(--size-3);
	}

	> * {
		flex: 1;
		height: fit-content;

		@media (min-width: $breakpoint-sm) {
			padding-left: var(--size-6);
			border-left: 1px solid var(--color-neutral-opacity20);
		}

		@media (min-width: $breakpoint-lg) {
			padding-left: var(--size-8);
		}

		&:first-child {
			margin-bottom: 0;
			border-bottom: 1px solid var(--color-neutral-opacity20);

			@media (min-width: $breakpoint-sm) {
				border-bottom: 0;
			}

			@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md-max) {
				margin-bottom: var(--size-12);
			}
		}
	}

	li {
		&:first-child a {
			padding-top: 0;
		}

		&:last-child a {
			padding-bottom: 0;
		}
	}
}

.bottom {
	background-color: var(--color-neutral-opacity10);

	&__inner {
		display: flex;
		justify-content: space-between;
		font-size: var(--font-size-14);

		> * {
			display: flex;
			align-items: center;
			height: var(--size-16);
			padding: 0;
		}

		@media (max-width: $breakpoint-xs-max) {
			flex-direction: column-reverse;
			align-items: center;
			padding: 0;
		}

		ul {
			display: flex;
			gap: var(--size-5);

			@media (min-width: $breakpoint-sm) {
				gap: var(--size-8);
			}
		}
	}
}

.social_menu {
	display: flex;
	gap: var(--size-2);
	justify-content: center;

	a {
		padding: var(--size-2);
	}

	@media (max-width: $breakpoint-xs-max) {
		width: 100%;
		border-bottom: 1px solid rgba(255, 255, 255, 10%);
	}

	@media (min-width: $breakpoint-sm) {
		margin-right: calc(var(--size-3) * -1);
	}
}
