@use 'libs/theme' as *;

.attach {
	width: fit-content;
	color: var(--color-primary);
	background-color: inherit;
}

.file_input {
	display: none;
}

.multiple_checkbox_wrapper {
	display: flex;
	flex-flow: column;
	gap: var(--size-3);
}

.uploaded_files {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	justify-content: space-between;
	max-width: 480px;
	margin-top: var(--size-4);
}

.file_container {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.files_container {
	width: fit-content;
}

.list_container {
	display: flex;
	gap: var(--size-4);
	align-items: center;
	justify-content: space-between;
	padding: var(--size-2) 0;
	border-bottom: 1px solid var(--color-dark-blue-white90);
}

.remove_button {
	padding: 0;
	background-color: var(--color-light-blue-white50);
	cursor: pointer;
}
