@use 'libs/theme' as *;

.person_card_wrapper {
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	align-items: flex-start;
	width: var(--grid-columns-12);
	max-width: 324px;
	background-color: var(--color-neutral-white);
	border-radius: var(--size-2);
}

.person_image_wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
}

.person_image {
	width: 100%;
	height: 100%;
	aspect-ratio: 1 / 1;
	overflow: hidden;
	border-radius: var(--size-2) var(--size-2) 0 0;
}

.person_info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: var(--responsive-spacing-24);
	row-gap: var(--responsive-spacing-24);
}

.person_details {
	container-type: inline-size;

	h3 {
		align-self: stretch;
		min-height: 2.5em;
		margin-bottom: var(--size-4);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-24);

		@include get-font-size(260, 20);
	}

	p {
		align-self: stretch;
		margin: 0;
		font-size: var(--font-size-16);
	}
}

$iconSize: 24px;

.person_contacts {
	display: flex;
	flex-direction: column;
	gap: var(--size-2);
	align-items: flex-start;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	@media (min-width: $breakpoint-sm) {
		display: grid;
		grid-template-rows: 1fr 1fr;
	}

	&__icon {
		flex-shrink: 0;
	}

	a {
		overflow: hidden;
		color: var(--color-middle-blue-base);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
		white-space: nowrap;
		text-decoration: none;
		text-overflow: ellipsis;
	}

	div {
		display: flex;
		flex-direction: row;
		gap: var(--size-2);
		align-items: center;
		overflow: hidden;
	}
}
