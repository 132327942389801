@use 'libs/theme' as *;

.logout_button {
	padding-top: var(--size-3);
}

.calendar_container {
	padding: 0;
	background-color: var(--color-dark-blue-white97);
}

.tabs_wrapper {
	@media (min-width: $breakpoint-lg) {
		display: none;
	}
}

.tabs_sections {
	@media (min-width: $breakpoint-lg) {
		display: none;
	}
}

.timeline_and_tasks {
	display: none;
	justify-content: space-between;

	@media (min-width: $breakpoint-lg) {
		display: flex;
	}

	.calendar {
		display: flex;
		flex-direction: column;
		gap: var(--responsive-spacing-36);
		width: var(--grid-columns-7);
		padding-top: var(--responsive-spacing-72);
		padding-bottom: var(--responsive-spacing-72);

		&__items {
			display: flex;
			flex-direction: column;
			gap: var(--size-4);
		}
	}

	.tasks {
		width: var(--grid-columns-4);
	}
}

.empty_state_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 240px;
	padding: 0 var(--responsive-spacing-24);
	font-size: var(--font-size-20);
	text-align: center;
	border: 2px dashed rgba(0, 32, 57, 0.25);
	border-radius: 8px;

	@media (min-width: $breakpoint-sm) {
		font-size: var(--font-size-18);
	}
}

.horizontal_layout {
	@media (min-width: $breakpoint-lg) {
		flex-direction: row;
		gap: 0;
		padding: var(--responsive-spacing-24) var(--responsive-spacing-24) var(--responsive-spacing-24)
			var(--responsive-spacing-36);
	}
}

.loader {
	width: var(--grid-columns-12);
	height: 70vh;
}

.loader_wrapper {
	padding-bottom: var(--responsive-spacing-96);
}
