@use 'libs/theme' as *;

.content {
	display: flex;
	gap: var(--size-6);
	align-items: center;
	margin: var(--responsive-spacing-96) auto;
	font-weight: var(--font-weight-semi-bold);

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}

.buttons {
	display: flex;
	gap: var(--size-1);

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--size-12);
		height: var(--size-12);
		background-color: var(--color-dark-blue-white95);
		border-radius: var(--border-radius-medium);
	}
}
