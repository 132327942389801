@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing;
}

.content {
	--content-gap: var(--size-4);

	display: flex;
	flex-wrap: wrap;
	gap: var(--content-gap);
	justify-content: flex-start;
	width: 100%;
}

.card {
	width: 100%;

	> div {
		width: 100%;
	}

	&__half {
		@media (min-width: $breakpoint-md) {
			width: calc(50% - var(--content-gap) / 2);
		}
	}
}
