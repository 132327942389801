@use 'libs/theme' as *;

.container {
	@include themed-spacing;

	h3 {
		margin-bottom: var(--responsive-spacing-36);
	}
}

.skeleton_theme {
	height: 200px;
}

.pages {
	display: flex;
	flex-direction: column;
	gap: var(--size-2);

	@media (min-width: $breakpoint-md) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: var(--size-4);
	}

	@media (min-width: $breakpoint-lg) {
		grid-template-columns: repeat(3, 1fr);
		gap: var(--size-4);
	}

	.content {
		display: none;

		@media (min-width: $breakpoint-md) {
			display: block;
		}
	}
}
