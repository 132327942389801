@mixin overflow-shadow(
	$width,
	$color-start: rgba(0, 0, 0, 0%),
	$color-end: rgba(0, 0, 0, 100%),
	$gradient-start: 100%
) {
	position: absolute;
	pointer-events: none;
	inset: 0;

	&::before,
	&::after {
		position: absolute;
		top: 0;
		display: block;
		width: calc($width * 2);
		height: 100%;
		opacity: 0;
		transition: opacity 200ms ease-out;
		content: ' ';
	}

	&::before {
		left: 0;
		background: linear-gradient(270deg, $color-end 0%, $color-start $gradient-start);
	}

	&.shadow__before::before {
		opacity: 1;
	}

	&::after {
		right: 0;
		background: linear-gradient(90deg, $color-end 0%, $color-start $gradient-start);
	}

	&.shadow__after::after {
		opacity: 1;
	}
}
