@use 'libs/theme' as *;

.container {
	display: flex;
	padding: 0;
}

.info_link_list {
	display: flex;
	flex: 0 0 auto;
	gap: var(--size-3);
	margin-bottom: var(--size-6);
	text-decoration: none;
}

.ticket_icon {
	min-width: var(--size-6);
}

.info_button_label {
	font-weight: var(--font-weight-semi-bold);
}

.arrow_icon {
	min-width: var(--size-6);
	margin-right: 0;
}

.benefit {
	margin-bottom: var(--size-6);
	list-style: none;

	&__content {
		display: flex;
		gap: var(--size-3);
		align-items: flex-start;
		text-decoration: none;
	}

	&__item {
		display: flex;
		text-align: left;

		p {
			font-size: var(--font-size-16);
		}

		.link_icon {
			min-width: var(--size-6);
			margin-left: var(--size-3);
			color: var(--color-middle-blue-base);
		}
	}
}
