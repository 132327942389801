@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	padding: 0 var(--responsive-spacing-24);

	@media (min-width: $breakpoint-sm) {
		padding-right: var(--size-8);
		padding-left: var(--size-8);
	}

	@media (min-width: $breakpoint-md) {
		padding-right: var(--size-10);
		padding-left: var(--size-10);
	}

	@media (min-width: $breakpoint-lg) {
		padding-right: var(--size-12);
		padding-left: var(--size-12);
	}

	strong a {
		color: var(--color-secondary);
		text-decoration: none;
	}
}

.head {
	display: flex;
	flex-direction: column;
	margin-bottom: var(--size-4);

	p {
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
	}
}

.block {
	display: flex;

	h3 {
		@include h4;
	}
}

.button {
	margin-top: var(--responsive-spacing-48);
}
