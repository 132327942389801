@mixin disabled {
	&:disabled,
	&[aria-disabled='true'],
	&[disabled],
	[disabled] & {
		@content;
	}
}

@mixin not-disabled {
	&:not(:disabled, [aria-disabled='true'], [disabled]) {
		@content;
	}
}

@mixin hover($allowDisabled: false) {
	@if $allowDisabled {
		&:hover,
		&:focus-visible {
			@content;
		}
	} @else {
		@include not-disabled {
			&:hover,
			&:focus-visible {
				@content;
			}
		}
	}
}

// Mixin for the active state
@mixin active($allowDisabled: false) {
	@if $allowDisabled {
		&[aria-selected='true'],
		&:active {
			@content;
		}
	} @else {
		@include not-disabled {
			&[aria-selected='true'],
			&:active {
				@content;
			}
		}
	}
}

@mixin is-a-tag {
	/* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector -- false positive */
	a:has(&),
	a > &,
	&:is(a) {
		@content;
	}
}
