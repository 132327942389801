@use 'libs/theme' as *;

.content {
	margin: var(--responsive-spacing-24) auto var(--responsive-spacing-48);
	padding-bottom: var(--responsive-spacing-24);
	font-size: var(--font-size-14);
	border-bottom: 1px solid var(--color-dark-blue-white90);

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: var(--size-2);
	margin-top: var(--responsive-spacing-24);
}

.tag {
	display: inline-block;
	padding: 2px var(--size-2);
	font-weight: var(--font-weight-semi-bold);
	text-transform: uppercase;
	text-decoration: none;
	background-color: var(--palette-sand-primary);
	border-radius: var(--border-radius-small);
}
