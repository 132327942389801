@use 'sass:map';
@use '../../__GENERATED__/palettes' as *;
@forward '../../__GENERATED__/palettes';

@mixin palette-variants {
	@each $paletteColor in map.keys($palettes) {
		&__#{$paletteColor} {
			--palette-primary: #{map.get($palettes, $paletteColor, 'primary')};
			--palette-secondary: #{map.get($palettes, $paletteColor, 'secondary')};
			--palette-tertiary: #{map.get($palettes, $paletteColor, 'tertiary')};
		}
	}
}
