@use 'libs/theme' as *;

.theme {
	@include themed-colors;
}

.outerWrapper {
	&__webpage {
		margin-bottom: var(--responsive-spacing-96);
	}

	&__members_page {
		margin-bottom: var(--responsive-spacing-72);
	}

	.heroWrapper {
		padding: 0;

		.inner_container {
			position: relative;
			display: flex;
			margin-bottom: var(--responsive-spacing-96);
			padding: var(--size-18) var(--size-6) var(--size-18) var(--size-6);

			@media (min-width: $breakpoint-sm) {
				padding: var(--size-18) var(--size-8) var(--size-18) var(--size-8);
			}

			@media (min-width: $breakpoint-md) {
				gap: var(--grid-columns-1);
				padding: var(--size-18) var(--size-10) var(--size-18) var(--size-10);
			}

			@media (min-width: $breakpoint-lg) {
				padding: var(--size-24) var(--size-12) var(--size-24) var(--size-12);
			}

			&__hasWhiteBackground {
				padding-bottom: 0;
			}
		}

		.column {
			&__center {
				width: var(--grid-columns-12);
				margin: 0 auto;

				@media (min-width: $breakpoint-md) {
					width: var(--grid-columns-8);
				}
			}

			&__left {
				&__hasIllustration {
					width: var(--grid-columns-12);

					@media (min-width: $breakpoint-md) {
						width: var(--grid-columns-6);
					}
				}

				&__noIllustration {
					width: var(--grid-columns-12);

					@media (min-width: $breakpoint-md) {
						width: var(--grid-columns-8);
					}
				}

				&__illustration {
					display: flex;
					flex-flow: column;
					align-items: flex-end;

					@media (min-width: $breakpoint-md) {
						width: var(--grid-columns-5);
					}

					.image_container {
						position: absolute;
						top: 48px;
						right: 0;
						bottom: calc(var(--size-9) * -1);
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						width: var(--grid-columns-5);

						@media (min-width: $breakpoint-lg) {
							bottom: calc(var(--size-12) * -1);
						}

						img {
							width: auto;
							max-width: 100%;
							max-height: 100%;
						}

						&__hasWhiteBackground {
							bottom: calc(var(--size-18) * -1);
						}
					}
				}
			}
		}

		.header_container {
			h1:first-child {
				margin-top: var(--size-4);
			}

			&__title {
				margin: var(--size-4) 0 0 0;
				text-transform: uppercase;
				hyphens: auto;
			}
		}

		.prefix_title {
			color: var(--color-middle-blue-base);
			text-transform: uppercase;
		}

		.subtitle {
			margin-top: var(--size-4);
			font-size: 16px;

			@media (min-width: $breakpoint-sm) {
				font-size: 18px;
			}

			@media (min-width: $breakpoint-lg) {
				font-size: 20px;
			}
		}
	}
}
