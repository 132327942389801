@use 'libs/theme' as *;

.line {
	width: var(--size-35);
	height: var(--size-1);
	margin-bottom: var(--size-4);
	background-color: var(--color-middle-blue-base);
	animation: line 0.8s ease-out;
	animation-fill-mode: forwards;
}

@keyframes line {
	0% {
		width: 0;
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	100% {
		width: 120px;
	}
}
