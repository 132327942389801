@use 'libs/theme' as *;

.tabs_container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: var(--size-12);
	width: 100%;

	&::after {
		position: absolute;
		right: 0;
		z-index: var(--z-index-content-highlighted);
		display: block;
		width: 32px;
		height: 48px;
		background: linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
		content: '';

		@media (min-width: $breakpoint-sm) {
			display: none;
		}
	}
}

.tab_list {
	display: flex;
	gap: 16px;
	width: 100%;
	overflow-x: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	scroll-behavior: smooth;
	white-space: nowrap;

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}

	.swiper {
		display: flex;
		align-items: center;
		height: 48px;
		border-bottom: 1px solid var(--color-dark-blue-white95);
	}
}

.tab {
	width: fit-content !important;
	margin-right: 24px;
	padding: 0;
	overflow: hidden;
	color: var(--color-primary);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
	background-color: transparent;
	border-bottom: 2px solid transparent;
	cursor: pointer;

	&__active {
		border-color: var(--color-secondary);
	}
}
