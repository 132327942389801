@use 'libs/theme' as *;

.videoContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	cursor: pointer;

	video {
		aspect-ratio: 16/9;
		width: 100%;
	}

	p {
		@include nav;

		padding-left: var(--size-3);
		color: var(--color-neutral-white);
	}
}

.playOverlay {
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: var(--size-4);
	background: var(--color-linear-gradient2);
	border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
}

.iconView {
	display: flex;
	align-items: center;
	cursor: pointer;

	p {
		@include nav;

		padding-left: var(--size-3);
		color: var(--color-neutral-white);
	}
}

.player {
	display: none;
	isolation: isolate;
}

.on_play {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: var(--color-dark-blue-75);
	cursor: default;

	&__desktop {
		top: 0;
		left: 0;
		z-index: var(--z-index-overlay);
	}

	video {
		width: 100%;
		border-radius: var(--border-radius-large);
		cursor: pointer;
		aspect-ratio: 16/9;
	}
}

.dismiss {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
	padding: var(--size-2);
	background: var(--color-linear-gradient1);
	border-radius: 0 0 0 var(--border-radius-medium);
	cursor: pointer;

	@media (min-width: $breakpoint-lg) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.aspect__4_3 {
	video {
		background-color: black;
	}

	> video {
		aspect-ratio: 4 / 3;
		object-fit: cover;
	}
}
