@use 'libs/theme' as *;

.navigation_text_item {
	display: flex;
	gap: var(--size-2);
	align-items: top;
	color: var(--color-dark-blue-white97);

	svg {
		color: var(--color-secondary);
	}
}

.icon {
	padding: var(--size-2) 0;

	&__reverse {
		flex-direction: row-reverse;
		align-items: top;
		justify-content: flex-end;
	}

	&__active {
		border-bottom: 2px solid var(--color-middle-blue-base);
	}
}
