@use 'libs/theme' as *;
@use './simple-richtext.scss' as *;

.block {
	.buttonContainer {
		display: flex;

		+ .buttonContainer {
			margin-top: var(--size-4);
		}

		&.alignment {
			&-left {
				justify-content: flex-start;
			}

			&-center {
				justify-content: center;
			}

			&-right {
				justify-content: flex-end;
			}
		}
	}

	a {
		color: var(--palette-tertiary);
		font-weight: var(--font-weight-semi-bold);
		text-decoration: none;
	}

	ol {
		::marker {
			color: var(--palette-tertiary);
			font-weight: 600;
		}
	}

	ul {
		list-style: none;

		li::before {
			position: relative;
			left: calc(var(--size-4) * -1);
			display: inline-block;
			width: 8px;
			height: 10px;
			margin-left: -8px;
			background-color: var(--palette-tertiary);
			content: '';
			mask-image: url('/assets/li-arrow.svg');
		}
	}

	li {
		padding-left: var(--size-2);
	}

	h2,
	h3,
	h4 {
		margin-top: 2em;
		margin-bottom: 0.5em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p + p {
		margin-top: var(--size-fluid-xsmall);
	}

	.imageContainer {
		padding-top: var(--size-2);
		padding-bottom: var(--size-2);

		&.widerThanText {
			width: calc(100% + var(--wider) * 2);
			margin-left: var-neg(--wider);
			padding-top: var(--size-6);
			padding-bottom: var(--size-6);
		}

		&:not(.widerThanText) {
			.image {
				display: flex;
			}
		}

		&.alignment {
			&-left {
				.image {
					justify-content: flex-start;
				}
			}

			&-center {
				.image {
					justify-content: center;
				}
			}

			&-right {
				.image {
					justify-content: flex-end;
				}
			}
		}
	}

	table {
		width: 100%;
		border-color: var(--palette-secondary);

		th,
		td {
			border-color: var(--palette-secondary);
		}
	}

	p,
	ul,
	ol,
	table {
		margin-top: 1em;
		margin-bottom: 1em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 1em;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
