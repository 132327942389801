@use 'libs/theme' as *;

.benefits_group_wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	width: var(--grid-columns-12);

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}

.benefits_group {
	display: flex;
	flex-direction: column;
	width: var(--grid-columns-12);

	h4 {
		margin-bottom: var(--size-4);
	}
}

.benefits_list {
	display: flex;
	flex-direction: column;
}

.benefit {
	display: flex;
	gap: var(--size-6);
	align-items: flex-start;
	justify-content: space-between;
	padding: var(--size-3);

	&:nth-of-type(odd) {
		background-color: var(--color-dark-blue-white97);
	}

	&__text {
		display: flex;
		gap: var(--size-4);
		text-align: left;

		p {
			margin: 0, 0, var(--size-1), 0;

			&:first-child {
				font-weight: var(--font-weight-semi-bold);
			}
		}
	}

	&__calc_result {
		font-weight: var(--font-weight-semi-bold);
		white-space: nowrap;
	}
}

.tick_icon {
	min-width: var(--size-6);
}

.loader_wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--size-1);
	width: var(--grid-columns-12);
	padding: 0 var(--size-6);
}

.loader {
	width: var(--grid-columns-12);
	height: var(--size-16);
	border-radius: 8px;
}
