@use 'libs/theme' as *;

.modal_content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	h2 {
		@include h3;
	}

	p {
		margin-top: var(--size-4);
		font-size: var(--font-size-16);
	}

	&__buttons {
		display: flex;
		gap: var(--size-4);
		margin-top: var(--responsive-spacing-48);
	}
}

.single_button {
	margin-top: var(--responsive-spacing-48);
}

.skip_button {
	color: var(--color-dark-blue-base);
	background-color: var(--color-light-blue-white50);
	border: none;
}
