@use 'libs/theme' as *;

.faq {
	@include themed-spacing;
}

.blocks {
	margin-top: var(--responsive-spacing-48);

	> div {
		margin-bottom: var(--responsive-spacing-48);

		&:last-child {
			margin-bottom: 0;
		}
	}
}
