@use 'libs/theme' as *;

.container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-index-overlay);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.modal_overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-index-overlay);
	width: 100vw;
	height: 100vh;
	background: linear-gradient(0deg, rgba(0, 170, 206, 0.25), rgba(0, 170, 206, 0.25)), rgba(0, 32, 57, 0.25);
}

.modal {
	z-index: var(--z-index-overlay);
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	max-width: 896px;
	padding: var(--responsive-spacing-36) var(--size-6) var(--responsive-spacing-48) var(--size-6);
	background-color: var(--color-neutral-white);

	@media (min-width: $breakpoint-sm) {
		margin: 0 var(--size-8) 0 var(--size-8);
		padding-right: var(--responsive-spacing-36);
		padding-left: var(--responsive-spacing-36);
		border-radius: var(--size-2);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}
