.definition_list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	&::after {
		display: block;
		clear: both;
		content: ' ';
	}

	dt {
		font-weight: var(--font-weight-semi-bold);
	}

	dt,
	dd {
		flex-grow: 1;
		flex-shrink: 0;
		width: 50%;
		padding: var(--size-3);
		border-radius: var(--size-1);

		&:nth-of-type(odd) {
			background-color: var(--color-dark-blue-white97);
		}
	}
}
