@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing(96, 72);
}

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	align-items: center;
	justify-content: center;

	h2 {
		text-align: center;
	}
}

.loader {
	width: 100%;
	height: 280px;
}

.loading {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.empty_state {
	width: var(--grid-columns-12);
	text-align: center;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}
