@use 'libs/theme' as *;

.theme {
	@include themed-colors;
}

.relative {
	position: relative;
}

.overlay {
	@media (min-width: $breakpoint-md) {
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.image {
	aspect-ratio: 375 / 300;

	@media (min-width: $breakpoint-sm) {
		max-height: 560px;
		aspect-ratio: 2 / 1;
	}
}

.container {
	@include themed-colors;

	@media (min-width: $breakpoint-md) {
		display: flex;
		flex-direction: row-reverse;
	}
}

.content {
	@include themed-colors;

	padding: var(--responsive-spacing-48) 0;

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-5);
		margin-top: var(--responsive-spacing-36);
		padding-right: var(--size-9);
		padding-left: var(--size-9);
		border-radius: var(--border-radius-large);
	}
}

.phone {
	@include h1;

	margin-bottom: var(--size-3);
	color: var(--color-secondary);
	text-decoration: none;
}

.email {
	font-weight: var(--font-weight-semi-bold);
	text-decoration: none;
}

.contact_link {
	margin-top: var(--responsive-spacing-24);
	padding-top: var(--responsive-spacing-24);
	border-top: 1px solid var(--color-neutral-opacity20);
}
