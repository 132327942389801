@use 'libs/theme' as *;

.dropdown_menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: var(--z-index-content-highlighted);
	width: 100%;
	padding-top: var(--size-12);
	padding-bottom: var(--size-12);
	overflow-y: scroll;
	background: var(--color-primary);
	border-top: 1px solid rgba(255, 255, 255, 20%);

	@media (max-width: $breakpoint-xs-max) {
		display: none;
	}

	@media (min-width: $breakpoint-lg) {
		padding-top: var(--size-16);
		padding-bottom: var(--size-16);
	}
}

.container {
	@media (min-width: $breakpoint-sm) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: var(--size-12) var(--size-6);
	}

	@media (min-width: $breakpoint-md) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media (min-width: $breakpoint-lg) {
		gap: var(--size-16) var(--size-8);
	}
}

.title {
	font-weight: var(--font-weight-semi-bold);

	@media (min-width: $breakpoint-sm) {
		display: none;
	}
}

.item {
	li {
		margin: var(--size-5) 0;

		a {
			padding: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	svg {
		margin-top: var(--size-1);
	}

	a {
		padding: 0;
	}

	&__title {
		font-weight: var(--font-weight-semi-bold);
		text-transform: uppercase;

		@media (min-width: $breakpoint-sm) {
			margin-top: 0 !important;
		}
	}

	&__child {
		@media (max-width: $breakpoint-xs-max) {
			display: none;
		}
	}

	&__more {
		margin-left: var(--size-6);
		color: var(--color-secondary);
		font-weight: var(--font-weight-semi-bold);
		text-decoration: none;

		@media (max-width: $breakpoint-xs-max) {
			display: none;
		}
	}
}

.hidden {
	@media (max-width: $breakpoint-xs-max) {
		display: none;
	}
}

.show_all {
	margin-top: var(--size-6);
	margin-left: var(--size-6);
	padding: 0;
	color: var(--color-secondary);
	font-weight: var(--font-weight-semi-bold);
	text-transform: none;
	background-color: transparent;
	border: 0;

	@media (min-width: $breakpoint-sm) {
		display: none;
	}
}
