/* stylelint-disable property-no-unknown */
$breakpoint-xs: 375px;
$breakpoint-sm: 640px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;
$breakpoint-xs-max: calc($breakpoint-sm - 1px);
$breakpoint-sm-max: calc($breakpoint-md - 1px);
$breakpoint-md-max: calc($breakpoint-lg - 1px);
$breakpoint-lg-max: calc($breakpoint-xl - 1px);

:export {
	breakpoint-xs: $breakpoint-xs;
	breakpoint-xs-max: $breakpoint-xs-max;
	breakpoint-sm: $breakpoint-sm;
	breakpoint-sm-max: $breakpoint-sm-max;
	breakpoint-md: $breakpoint-md;
	breakpoint-md-max: $breakpoint-md-max;
	breakpoint-lg: $breakpoint-lg;
	breakpoint-lg-max: $breakpoint-lg-max;
	breakpoint-xl: $breakpoint-xl;
}
