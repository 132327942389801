@use 'libs/theme' as *;

.theme {
	position: relative;
}

.image {
	aspect-ratio: 375 / 300;

	@media (min-width: $breakpoint-sm) {
		max-height: 560px;
		aspect-ratio: 2 / 1;
	}
}

.gradient {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@media (min-width: $breakpoint-sm) {
		position: absolute;
		inset: 0;
		background: linear-gradient(180deg, rgba(0, 32, 57, 0%) 0%, #002039 100%);
	}
}

.container {
	padding-top: var(--responsive-spacing-48);
	padding-bottom: var(--responsive-spacing-48);
	background-color: var(--color-dark-blue-base);

	@media (min-width: $breakpoint-sm) {
		background-color: transparent;
	}
}

.content {
	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}

	h1 {
		margin-top: var(--size-4);
	}
}

.title {
	margin-top: var(--size-4);
	color: var(--color-neutral-white);
	text-transform: uppercase;
	hyphens: auto;

	&__prefix {
		margin-bottom: 0;
		color: var(--color-middle-blue-base);
		text-transform: uppercase;
	}
}
