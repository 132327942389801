@use 'libs/theme' as *;

.task_container {
	display: flex;
	gap: var(--size-4);
	align-items: flex-start;
	justify-content: flex-start;
	padding: var(--size-4) var(--size-6);
	text-align: left;
	text-decoration: none;
	background-color: var(--color-neutral-white);
}

.task_header {
	margin-bottom: var(--size-1);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
}

.deadline_overdue {
	color: var(--color-signal-red);
}

.not_ready {
	opacity: 0.5;
}

.modal_content {
	h3 {
		margin-bottom: var(--size-4);
	}

	p {
		margin-bottom: var(--responsive-spacing-48);
	}
}

.unclickable {
	pointer-events: none;
}
