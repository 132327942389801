@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	padding: 0 var(--responsive-spacing-24);

	@media (min-width: $breakpoint-sm) {
		padding-right: var(--size-8);
		padding-left: var(--size-8);
	}

	@media (min-width: $breakpoint-md) {
		padding-right: var(--size-10);
		padding-left: var(--size-10);
	}

	@media (min-width: $breakpoint-lg) {
		padding-right: var(--size-12);
		padding-left: var(--size-12);
	}
}

.head {
	display: flex;
	flex-direction: column;
	gap: var(--size-6);
	margin-bottom: var(--size-4);

	p {
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
	}
}

.section {
	display: flex;
	flex-direction: column;

	h3 {
		@include h4;
	}
}

.input_text_container {
	display: flex;
	flex-direction: column;
}

.radio_group_container {
	display: flex;
	flex-direction: column;
}

.select_container {
	width: var(--grid-columns-12);
	margin-bottom: var(--size-9);

	@media (min-width: $breakpoint-lg) {
		margin-bottom: var(--size-12);
	}
}

.input_text {
	width: 100%;
	margin-bottom: var(--size-9);
}

.input_section {
	@media (min-width: $breakpoint-md) {
		display: flex;
		gap: var(--size-4);
		width: 100%;
	}
}

.label_alt {
	margin-bottom: var(--size-4);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
}

.submit {
	width: fit-content;
}

.attach {
	width: fit-content;
	color: var(--color-primary);
	background-color: inherit;
}

.file_input {
	display: none;
}
