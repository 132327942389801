@use 'libs/theme' as *;

.header {
	position: relative;
	top: 0;
	z-index: var(--z-index-header);
	display: flex;
	align-items: center;
	height: var(--size-header);
	background-color: var(--color-primary);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 20%);

	@media (min-width: $breakpoint-md) {
		height: var(--size-header-md);
	}
}

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menu {
	display: none;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		gap: var(--size-8);
		align-items: center;
		margin-left: var(--size-16);
	}

	@media (min-width: $breakpoint-md) {
		margin-left: var(--size-20);
	}

	a,
	button {
		padding: var(--size-2) 0;
		color: white;
		font-weight: var(--font-weight-semi-bold);
		text-transform: uppercase;
		text-decoration: none;
	}

	&__primary {
		@media (max-width: $breakpoint-sm-max) {
			display: none;
		}
	}

	&__active {
		border-bottom: 2px solid var(--color-middle-blue-base);

		@include multiplier {
			padding-bottom: calc(var(--size-2) - 2px);
		}
	}

	&__icons {
		display: flex;
		gap: var(--size-2);
		margin-right: calc(-1 * var(--size-3));

		@media (min-width: $breakpoint-sm) {
			position: relative;
			margin-right: calc(-1 * var(--size-4));
		}
	}
}

.campaign {
	justify-content: center;
	height: 64px;
}

.centered {
	display: flex;
	align-items: center;
}

.logo {
	svg {
		width: auto !important;
		height: 24px !important;

		@media (min-width: $breakpoint-md) {
			height: 28px !important;
		}
	}
}
