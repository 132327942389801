@use 'libs/theme' as *;

.theme {
	@include themed-colors;
	@include themed-spacing(96, 72);

	h2 {
		margin-bottom: var(--responsive-spacing-48);

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-8);
		}
	}

	h3 {
		margin-bottom: var(--size-4);
	}
}

.container {
	&__alignCenter {
		h2 {
			width: var(--grid-columns-8);
			margin-right: auto;
			margin-left: auto;
			text-align: center;
		}

		.content_grid {
			justify-content: center;
		}
	}
}

.content_grid {
	--gap: var(--size-12);

	@media (min-width: $breakpoint-lg) {
		--gap: var(--size-16);
	}

	display: flex;
	flex-wrap: wrap;
	width: calc(100% + var(--gap));
	margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));

	--col-6: calc(var(--grid-columns-6) - var(--gap));
	--col-4: calc(var(--grid-columns-4) - var(--gap));
	--col-3: calc(var(--grid-columns-3) - var(--gap));

	> * {
		margin: var(--gap) 0 0 var(--gap);

		@media (min-width: $breakpoint-sm) {
			flex-basis: var(--col-6);
		}

		@media (min-width: $breakpoint-md) {
			flex-basis: var(--col-4);
		}
	}

	&__2_columns > * {
		@media (min-width: $breakpoint-sm) {
			flex-basis: var(--col-6);
		}
	}

	&__3_columns > * {
		@media (min-width: $breakpoint-md) {
			flex-basis: var(--col-4);
		}
	}

	&__4_columns > * {
		@media (min-width: $breakpoint-lg) {
			flex-basis: var(--col-3);
		}
	}
}

.item {
	&__media {
		position: relative;
		width: 100%;
		aspect-ratio: 4/3;
		height: auto;
		margin-bottom: var(--size-6);
		overflow: hidden;
		border-radius: var(--border-radius-large);
	}

	&__actions {
		margin-top: var(--size-8);
	}

	&__action {
		width: fit-content;
		margin-top: var(--size-6);
	}
}
