@use 'libs/theme' as *;

.search {
	position: relative;
	margin: var(--responsive-spacing-72) auto var(--responsive-spacing-48);

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-8);
	}

	h1 {
		@include h3;

		margin-bottom: var(--size-8);
	}

	p {
		font-weight: var(--font-weight-semi-bold);

		span {
			color: var(--color-secondary);
		}
	}
}

.skeleton_theme {
	height: 200px;
}

.search__input {
	margin-bottom: var(--size-4);

	input,
	input:focus {
		--x-padding: var(--size-4);
		--y-padding: var(--size-3);

		@media (min-width: $breakpoint-sm) {
			--x-padding: var(--size-6);
			--y-padding: var(--size-5);
		}

		@media (min-width: $breakpoint-lg) {
			--x-padding: var(--size-8);
			--y-padding: var(--size-6);
		}

		padding-top: var(--y-padding);
		padding-bottom: var(--y-padding);
		padding-left: var(--x-padding);

		&:focus {
			padding-bottom: calc(var(--y-padding) - 1px);
		}
	}
}

.selected {
	background-color: var(--color-dark-blue-white95);
}

.suggestions {
	position: absolute;
	top: 115px;

	@media (min-width: $breakpoint-sm) {
		top: 130px;
	}

	@media (min-width: $breakpoint-lg) {
		top: 147px;
	}

	right: 0;
	left: 0;
	z-index: 3;
	background-color: #fff;
	border: 1px solid var(--color-dark-blue-white90);
	border-radius: 4px;
	box-shadow: 0 4px 16px 0 #0000001a;

	&__item {
		padding: 0.75em 1em;
		cursor: pointer;

		&:hover {
			background-color: var(--color-dark-blue-white95);
		}

		a {
			text-decoration: none;
		}

		&:not(:last-child) {
			border-bottom: 1px solid var(--color-dark-blue-white90);
		}
	}
}

.results {
	@include themed-colors;
}

.isLoading {
	opacity: 50%;
}

.list {
	margin: auto;
	padding: var(--responsive-spacing-48) 0 var(--responsive-spacing-72);

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-8);
	}
}

.no_results {
	margin: auto;
	padding: var(--responsive-spacing-96) 0;
	text-align: center;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}

	h3 {
		margin-bottom: var(--size-4);
	}
}
