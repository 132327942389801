@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	padding: 0 var(--responsive-spacing-24);

	@media (min-width: $breakpoint-sm) {
		padding-right: var(--size-8);
		padding-left: var(--size-8);
	}

	@media (min-width: $breakpoint-md) {
		padding-right: var(--size-10);
		padding-left: var(--size-10);
	}

	@media (min-width: $breakpoint-lg) {
		padding-right: var(--size-12);
		padding-left: var(--size-12);
	}
}

.header {
	margin-bottom: var(--responsive-spacing-48);
}

.uploaded_files {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	justify-content: space-between;
	max-width: 480px;
	margin-top: var(--size-4);
}

.list_container {
	display: flex;
	gap: var(--size-4);
	align-items: center;
	justify-content: space-between;
	padding: var(--size-2) 0;
	border-bottom: 1px solid var(--color-dark-blue-white90);
}

.list_container:first-of-type {
	border-top: 1px solid var(--color-dark-blue-white90);
}

.head {
	display: flex;
	flex-direction: column;
	gap: var(--size-6);
	margin-bottom: var(--size-4);

	p {
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
	}
}

.section {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);

	h3 {
		@include h4;
	}
}

.remove_button {
	padding: 0;
	background-color: var(--color-neutral-white);
	cursor: pointer;
}

.input_text_container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);
}

.radio_button_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.input_text {
	width: var(--grid-columns-12);
}

.input_section {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);

	@media (min-width: $breakpoint-md) {
		display: flex;
		flex-direction: row;
		gap: var(--size-4);
		width: 100%;
	}
}

.label {
	margin-bottom: var(--size-3);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
}

.label_alt {
	margin-bottom: var(--size-4);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-16);
}

.submit {
	width: fit-content;
	margin-top: var(--responsive-spacing-48);
}

.attach {
	width: fit-content;
	color: var(--color-primary);
	background-color: inherit;
}

.file_input {
	display: none;
}

.file_container {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.files_container {
	width: fit-content;
}
