@use 'libs/theme' as *;

$colors: (
	blue: var(--color-illustration-blue),
	red: var(--color-illustration-red),
	green: var(--color-illustration-green),
	orange: var(--color-illustration-orange),
	sand: var(--color-illustration-sand),
	grey: var(--color-illustration-grey),
);

.theme {
	@include themed-spacing;

	@each $color, $value in $colors {
		&.#{$color} {
			--quote-color: #{$value};
		}
	}

	hr {
		margin: 0;
		border-top-color: var(--quote-color);
		border-top-width: 2px;
	}
}

.content {
	margin: auto;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}

	// TODO: Remove this when the Quote icon is flattened
	svg,
	path {
		fill: none;
		stroke: var(--quote-color);
	}
}

.text {
	margin: var(--responsive-spacing-48) 0;

	&__body {
		margin-bottom: var(--size-6);
		font-size: var(--font-size-18);
	}

	&__byline {
		font-weight: var(--font-weight-semi-bold);
	}
}

.top {
	position: relative;
	display: flex;
	flex-direction: row;
	gap: var(--size-8);
	align-items: center;
	justify-content: flex-end;

	hr {
		width: calc(100% - var(--size-16) - var(--size-8));
	}
}

.icon {
	position: absolute;
	top: calc(-1 * var(--size-8));
	left: 0;
}
