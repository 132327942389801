@use 'libs/theme' as *;

.sub_header {
	margin-bottom: var(--responsive-spacing-36);
}

.tabs_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: var(--size-6);
	justify-content: center;
	cursor: pointer;

	&__item {
		padding: var(--size-3) 0;
	}
}

.line {
	position: relative;
	width: 100%;
	height: 1px;
	background-color: var(--palette-secondary);
	opacity: 0.2;
	content: '';
}

.line::before {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--palette-secondary);
	opacity: 0.2;
	content: '';
}

.selected {
	border-bottom: var(--size-05) solid var(--color-middle-blue-base);
}

.info_text {
	margin: var(--responsive-spacing-36) 0;
}

.graph_wrapper {
	width: var(--grid-columns-12);
	margin-bottom: var(--responsive-spacing-48);
	overflow-x: scroll;

	@media (min-width: $breakpoint-md) {
		overflow-x: unset;
	}
}

.swiper {
	position: relative;
	align-items: flex-end;
	width: var(--grid-columns-12);
	margin-bottom: var(--responsive-spacing-48);
	padding: 0 var(--size-4);
	overflow: visible;

	.swiper_item {
		display: flex;
		flex-direction: column;
		gap: var(--size-4);
		align-self: flex-end;
		width: var(--grid-columns-2);
		margin-right: var(--size-4);
		hyphens: auto;
	}

	&__bar {
		padding: var(--size-6);
		color: var(--color-dark-blue-base);
		background-color: var(--color-light-blue-base);
		border-radius: var(--size-2);
		cursor: pointer;
	}

	.swiper_item:first-child &__bar {
		color: var(--color-neutral-white);
		background-color: var(--color-dark-blue-base);
	}

	.swiper_item:last-child &__bar {
		background-color: var(--color-sand-white75);
	}

	&__no_data {
		padding: var(--size-6);
		color: var(--color-dark-blue-base);
		font-size: var(--font-size-14);
		background-color: var(--color-light-blue-white75);
		border-radius: var(--size-2);
	}

	@media (min-width: $breakpoint-md) {
		padding-right: 0;
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-10);
	}
}

.nav_right,
.nav_left {
	position: absolute;
	bottom: calc(50% - 24px);
	z-index: var(--z-index-content-highlighted);
	display: inline-flex;
	width: var(--size-12);
	height: var(--size-12);
	border-radius: var(--border-radius-round);

	@media (min-width: $breakpoint-md) {
		display: none;
	}

	svg {
		color: var(--color-neutral-white);
	}
}

.loader_slider {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 400px;
}

$iconSize: 28px; // plus padding

.nav_left {
	left: calc(($iconSize / 2) * -1);
}

.nav_right {
	right: calc(($iconSize / 2) * -1);
}

.bold {
	font-weight: var(--font-weight-semi-bold);
}

.disable {
	display: none;
}
