@use 'libs/theme' as *;

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	margin: auto;
	border-radius: var(--border-radius-large);
	row-gap: var(--size-8);

	@include themed-colors;
	@include themed-spacing(96, 36, true);

	@include multiplier {
		margin-top: var(--responsive-spacing-96);
		margin-bottom: var(--responsive-spacing-96);
	}

	@media (min-width: $breakpoint-md) {
		flex-direction: row;
		justify-content: space-between;
	}

	&__text {
		display: flex;
		flex-direction: column;
		gap: var(--size-4);

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-6);
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: var(--size-6);

		&__left {
			align-items: flex-start;

			@media (min-width: $breakpoint-md) {
				align-items: flex-end;
			}
		}

		a,
		button {
			width: fit-content;
		}
	}
}

.center {
	flex-direction: column;
	align-items: center;

	&__text {
		text-align: center;

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-12);
		}
	}

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}

[class*='content-drawer'] {
	.theme {
		@include multiplier {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.content {
		@include multiplier {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}
