@use 'libs/theme' as *;

.theme {
	overflow-x: hidden;

	@include themed-spacing(96, 0);
}

.content {
	width: var(--grid-columns-12);
	margin: auto;

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-12);
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-10);
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-8);
	}
}

.gallery {
	overflow: visible;
	border-radius: var(--border-radius-large);
	user-select: none;

	.fullscreen_icon {
		position: absolute;
		top: var(--size-2);
		right: var(--size-2);
		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--size-6);
		height: var(--size-6);
		background: var(--color-linear-gradient3);
		border-radius: var(--border-radius-medium);
		opacity: 0;
	}

	&__item {
		border-radius: var(--border-radius-large);
		opacity: 0.1;
		transition: opacity 0.2s var(--transition-timing-function-ease-in-out);
		pointer-events: none;

		img {
			margin-bottom: 0 !important;
			border-radius: var(--border-radius-large);
		}
	}

	.active_slide {
		opacity: 1;
		transition: opacity 0.2s var(--transition-timing-function-ease-in-out);
		pointer-events: auto;

		.fullscreen_icon {
			opacity: 1;
			transition: opacity 0.2s var(--transition-timing-function-ease-in-out);
		}

		img {
			transition: min-height 0.2s var(--transition-timing-function-ease-in-out);
		}
	}

	.media_container {
		position: relative;
		cursor: pointer;
	}
}

.utilities {
	display: flex;
	flex-direction: row;
	gap: var(--size-4);
	align-items: center;
	justify-content: flex-end;
	margin-top: var(--size-2);

	&__pagination {
		font-weight: var(--font-weight-semi-bold);
	}

	&__nav {
		display: flex;
		gap: var(--size-1);

		button {
			width: 40px;
			height: 40px;
			padding-right: calc(var(--size-scale) * 4.5);
			padding-left: calc(var(--size-scale) * 4.5);

			svg {
				color: var(--color-neutral-white);
			}
		}

		.disabled {
			opacity: 0.25;
			pointer-events: none;
		}
	}

	&__close_overlay {
		display: none;
	}
}

// OVERLAY

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-index-overlay);
	display: flex;
	align-items: center;
	width: 100vw;
	height: 100%;
	padding: 0;
	background-color: var(--color-dark-blue-75);

	@media (min-width: $breakpoint-md) {
		height: 100vh;
		padding: var(--size-8) calc(var(--size-8) + var(--size-10));
	}

	.gallery {
		width: var(--grid-columns-12);
		min-width: var(--grid-columns-12);
		max-width: var(--grid-columns-12);
		height: 100vh !important;
		overflow: hidden;
		border-radius: 0;

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-10);
			height: calc(100vh - 64px) !important;
		}

		@media (min-width: $breakpoint-lg) {
			max-width: unset;
		}

		.swiper_container {
			min-height: calc(100vh - 64px) !important;
		}

		&__item {
			height: 100vh !important;

			@media (min-width: $breakpoint-md) {
				height: calc(100vh - 64px) !important;
			}

			img {
				max-width: unset !important;
				min-height: 100vh !important;
				max-height: calc(100vh) !important;
				object-fit: contain !important;

				@media (min-width: $breakpoint-md) {
					max-height: calc(100vh - 64px) !important;
				}
			}
		}

		.active_slide {
			display: block;
		}

		.fullscreen_icon {
			display: none;
		}

		.media_container {
			height: 100vh;

			@media (min-width: $breakpoint-md) {
				height: calc(100vh - var(--size-8));
			}
		}
	}

	.utilities {
		z-index: var(--z-index-content-highlighted);

		&__pagination {
			position: fixed;
			right: var(--size-8);
			bottom: var(--size-8);
			color: var(--color-neutral-white);
		}

		&__nav {
			position: fixed;
			top: calc(50vh - 42px);
			right: 0;
			display: none;
			flex-direction: column;

			@media (min-width: $breakpoint-md) {
				display: flex;
			}

			button {
				border-radius: var(--border-radius-medium) 0 0 var(--border-radius-medium);
			}
		}

		&__close_overlay {
			position: fixed;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: var(--size-2);
			background: var(--color-linear-gradient1);
			border-radius: 0 0 0 var(--border-radius-medium);
			cursor: pointer;
		}
	}

	.from_image_gallery {
		min-height: calc(100vh - 64px) !important;

		> span {
			height: calc(100vh - 64px) !important;
		}

		span > span {
			height: 100% !important;
			min-height: 100% !important;
			padding-top: 100% !important;
		}
	}
}
