@use 'libs/theme' as *;

.modal_content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: var(--grid-columns-12);

	h2 {
		@include h3;

		margin-bottom: var(--size-4);
		font-weight: var(--font-weight-semi-bold);
	}

	p {
		margin-bottom: var(--responsive-spacing-48);
	}
}

.modal_buttons {
	display: flex;
	gap: var(--responsive-spacing-36);
	justify-content: flex-start;
}
