@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: var(--size-6);
	overflow: hidden;
	text-decoration: none;
	background-color: var(--color-neutral-white);
	border-radius: var(--border-radius-large);
}

.date {
	@include h2;

	margin-bottom: var(--size-6);
	color: var(--color-middle-blue-base);
	line-height: 1.25;
	white-space: nowrap;
	text-align: left;

	p {
		margin: 0;
	}

	&__day_count {
		color: var(--color-neutral-black);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--font-size-16);
	}

	@media (min-width: $breakpoint-md) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: var(--grid-columns-3);
	}

	@media (min-width: $breakpoint-lg) {
		margin-right: var(--size-8);
	}
}

.details {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: var(--size-6);
	align-items: flex-start;

	@media (min-width: $breakpoint-md) {
		justify-content: space-between;
	}

	@media (min-width: $breakpoint-lg) {
		flex-direction: row;
	}

	&__info {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: var(--size-6);
		justify-content: space-between;
		height: 100%;

		.title {
			@include h4;

			font-weight: var(--font-weight-semi-bold);
			text-align: left;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: var(--size-6);

		@media (min-width: $breakpoint-md) {
			align-self: center;
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: var(--size-2);

	&__item {
		display: flex;
		gap: var(--size-2);
		font-size: var(--font-size-16);
		text-align: left;

		&__icon_online {
			color: var(--color-middle-blue-base);
		}
	}
}
