@use 'libs/theme' as *;

.theme {
	@include themed-colors;
}

.content {
	@include themed-spacing;

	&__container {
		display: flex;
		flex-direction: column;
		max-width: none;
		padding: var(--responsive-spacing-36) var(--responsive-spacing-36) var(--responsive-spacing-48);
		background-color: var(--color-light-blue-white50);
		border-radius: var(--size-2);

		@media (min-width: $breakpoint-lg) {
			flex-direction: row;
		}

		.desc {
			margin-top: var(--size-4);
		}

		.price {
			margin-top: var(--size-12);
			list-style: none;
		}

		.cta {
			margin-top: var(--size-4);
		}

		.price_desc {
			margin-top: var(--size-4);
			font-size: var(--font-size-14);

			@include multiplier {
				a {
					color: var(--color-middle-blue-dark-blue20);
					font-weight: var(--font-weight-semi-bold);
					text-decoration: none;
				}
			}
		}
	}

	.tab_wrapper {
		margin-bottom: var(--responsive-spacing-48);
	}

	.info {
		width: var(--grid-columns-12);
		max-width: 480px;

		@media (min-width: $breakpoint-lg) {
			width: var(--grid-columns-6);
			max-width: none;
			padding-right: 96px;
		}
	}

	.lists {
		display: flex;
		flex-direction: column;
		width: var(--grid-columns-12);

		@media (min-width: $breakpoint-sm) {
			flex-direction: row;
			gap: var(--size-9);
		}

		@media (min-width: $breakpoint-lg) {
			flex-direction: row;
			gap: var(--size-12);
			width: var(--grid-columns-6);
		}

		.list {
			flex: 1;
			margin-top: var(--size-12);

			@media (min-width: $breakpoint-lg) {
				margin-top: 0;
			}

			ul {
				margin-top: var(--size-6);
			}

			.price {
				margin-top: var(--size-4);
				padding-bottom: var(--size-4);
				border-bottom: thin solid var(--color-dark-blue-10);

				&:last-of-type {
					border: none;
				}

				&__title {
					font-weight: var(--font-weight-semi-bold);
					font-size: var(--size-4);
				}
			}
		}
	}
}

.drawer {
	&__body_text {
		margin-top: var(--size-6);
	}

	&__link {
		display: flex;
		gap: var(--size-2);
		margin-top: var(--size-9);
		font-weight: var(--font-weight-semi-bold);
		font-size: var(--size-4);
	}
}

.line {
	position: relative;
	width: 100%;
	height: 1px;
	background-color: var(--palette-secondary);
	opacity: 0.2;
	content: '';
}
