@use 'libs/theme' as *;

.publication_list {
	margin: var(--responsive-spacing-96) 0;
	overflow: hidden;
	background-color: var(--color-dark-blue-white95);
}

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);
	padding-top: var(--responsive-spacing-72);
	padding-bottom: var(--responsive-spacing-72);

	h2 {
		text-align: center;
	}
}

.swiper {
	position: relative;
	align-items: center;
	width: var(--grid-columns-12);
	padding: 0 var(--size-4);
	overflow: visible;

	.swiper_item {
		margin-right: var(--size-4);
		user-select: none;
	}
}

.one_item {
	width: var(--grid-columns-12);
	margin: auto;

	@media (min-width: $breakpoint-sm) {
		max-width: initial;
		margin: 0;
	}
}

.two_items {
	@media (min-width: $breakpoint-sm) {
		width: calc(var(--grid-columns-12) - var(--size-4));
		padding-right: 0;

		> div {
			justify-content: center;
		}

		.swiper_item:last-of-type {
			margin-right: 0;
		}
	}

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-12);
	}
}

.three_items {
	@media (min-width: $breakpoint-md) {
		width: calc(var(--grid-columns-12) - var(--size-8));
		padding-right: 0;

		> div {
			justify-content: center;
		}

		.swiper_item:last-of-type {
			margin-right: 0;
		}
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-12);
	}
}

.four_items {
	@media (min-width: $breakpoint-lg) {
		width: calc(var(--grid-columns-12) - var(--size-12));
		padding-right: 0;

		> div {
			justify-content: center;
		}

		.swiper_item:last-of-type {
			margin-right: 0;
		}
	}
}

.nav_right,
.nav_left {
	position: absolute;
	bottom: calc(50% - 24px);
	z-index: var(--z-index-content-highlighted);
	width: var(--size-12);
	height: var(--size-12);
	border-radius: var(--border-radius-round);

	svg {
		color: var(--color-neutral-white);
	}
}

$iconSize: 28px; // plus padding

.nav_left {
	left: calc(($iconSize / 2) * -1);
}

.nav_right {
	right: calc(($iconSize / 2) * -1);
}

.disable {
	display: none;
}
