@use 'libs/theme' as *;

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	padding: var(--responsive-spacing-72) 0;
	background-color: var(--color-dark-blue-white95);
}

.no_results {
	display: flex;
	flex-direction: column;
	gap: var(--size-4);
	align-items: center;
	justify-content: center;
	padding: 0 var(--size-6);
	text-align: center;
	background-color: var(--color-dark-blue-white95);

	@media (min-width: $breakpoint-sm) {
		width: var(--grid-columns-8);
		padding: 0;
	}

	@media (min-width: $breakpoint-lg) {
		width: var(--grid-columns-6);
	}
}
