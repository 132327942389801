@use 'libs/theme' as *;

.content {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: var(--color-neutral-white);
	border-radius: var(--border-radius-large);

	&__image_container {
		max-width: 100%;
		padding: var(--size-6);
		border-bottom: 1px solid var(--color-dark-blue-white90);

		.placeholder_img_wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 1;
		}

		.image {
			aspect-ratio: 1 / 1;
		}
	}

	&__container {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: var(--size-6);
		justify-content: space-between;
		padding: var(--size-6);

		.info_line {
			display: flex;
			justify-content: space-between;
			margin-bottom: var(--size-4);
		}

		.tag {
			font-weight: var(--font-weight-semi-bold);
			font-size: var(--font-size-12);
			text-transform: uppercase;
		}

		.date {
			font-weight: var(--font-weight-regular);
			font-size: var(--font-size-12);
		}
	}

	.title__container {
		container-type: inline-size;
	}

	&__headline {
		display: -webkit-box;
		min-height: 2.5em; // = 2 lines of text
		overflow: hidden;
		font-weight: var(--font-weight-semi-bold);
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;

		@include get-font-size(276, 20);
	}

	.download {
		align-self: flex-start;
		font-weight: var(--font-weight-semi-bold);

		&_icon {
			margin-right: var(--size-2);
			color: var(--color-secondary);
		}
	}

	.issuuUrl {
		display: flex;
		gap: var(--size-2);
		align-items: center;
		align-self: flex-start;
		justify-content: center;

		svg {
			margin-right: 0;
		}

		p {
			font-weight: var(--font-weight-semi-bold);
			font-size: var(--font-size-16);
		}
	}
}

.extended {
	@include multiplier {
		@media (min-width: $breakpoint-sm) {
			min-height: auto;
			aspect-ratio: auto;

			.content {
				flex-direction: row;
				width: var(--grid-columns-12);
				margin: auto;

				&__image_container {
					width: var(--grid-columns-4);
					border-right: 1px solid var(--color-dark-blue-white90);
					border-bottom: none;
				}

				&__text_container {
					width: var(--grid-columns-8);
					height: auto;
					aspect-ratio: auto;
				}

				&__headline {
					font-size: 5.02cqw;
				}
			}
		}

		@media (min-width: $breakpoint-md) {
			.content {
				width: var(--grid-columns-10);
			}
		}

		@media (min-width: $breakpoint-lg) {
			.content {
				width: var(--grid-columns-8);
			}
		}
	}
}

.unstyled_link {
	text-decoration: none;
}
