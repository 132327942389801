@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--size-8);
	width: 50%;
	height: 100%;
	padding: var(--responsive-spacing-36);
	background-color: var(--color-light-blue-white75);
	border-radius: var(--size-2);
}

.phoneNumber {
	margin-bottom: var(--size-4);
	color: var(--color-middle-blue-base);
	white-space: nowrap;
}

.header {
	margin-bottom: var(--size-4);
}

.openHours {
	p {
		margin: 0;
	}
}

.button_container {
	display: flex;
	flex-direction: column;
	gap: var(--size-6);
	width: fit-content;
	margin: var(--size-8) 0 var(--size-4);

	@media (min-width: $breakpoint-sm) {
		flex-direction: row;
		gap: var(--size-4);
	}

	@media (min-width: $breakpoint-md) {
		flex-direction: column;
	}

	@media (min-width: $breakpoint-lg) {
		flex-direction: row;
	}
}
