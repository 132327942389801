@use 'libs/theme' as *;

.container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-index-overlay);
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.drawer {
	position: fixed;
	right: 0;
	display: flex;
	flex-direction: column;
	width: calc(100vw - var(--size-6));
	max-width: 100%;
	height: 100%;
	background-color: var(--color-neutral-white);

	@media (min-width: $breakpoint-sm) {
		width: 448px;
	}

	@media (min-width: $breakpoint-md) {
		width: 666px;
	}

	@media (min-width: $breakpoint-lg) {
		width: 688px;
	}

	@media (min-width: $breakpoint-xl) {
		width: 768px;
	}

	h2 {
		@include h3;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: var(--responsive-spacing-20);
	padding-bottom: var(--responsive-spacing-20);
	background-color: var(--color-primary);

	svg {
		display: block;
	}
}

.content {
	padding-top: var(--responsive-spacing-48);
	padding-bottom: var(--responsive-spacing-72);
	overflow-y: auto;
}

.navigation {
	display: flex;
	gap: var(--size-2);
}

.icon_button {
	padding: 0;
	background-color: transparent;
	border: 0;
	cursor: pointer;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-linear-gradient3);
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.not_visible {
	opacity: 0;
}
