@use 'libs/theme' as *;

.title {
	padding-bottom: var(--responsive-spacing-48);
	color: var(--color-dark-blue-base);
	text-align: left;

	h2 {
		@include h2;

		margin-bottom: var(--size-4);
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: var(--size-12);
	color: var(--color-dark-blue-base);
	text-align: left;

	h3 {
		@include h4;

		margin-bottom: var(--size-4);
	}

	p {
		font-size: var(--font-size-16);
	}

	dt {
		display: flex;
		justify-content: flex-end;
		text-align: end;
		hyphens: auto;
	}
}
