@use 'libs/theme' as *;

.result {
	position: relative;
	display: block;
	margin-bottom: var(--size-2);
	padding: var(--responsive-spacing-24) var(--responsive-spacing-20);
	background-color: var(--color-neutral-white);
	border-radius: var(--border-radius-large);

	h2 {
		@include h4;

		margin-bottom: var(--size-6);
	}

	a {
		position: absolute;
		inset: 0;
	}

	&__suggestion {
		font-weight: var(--font-weight-semi-bold);
		text-decoration: none;
	}
}

.paths {
	margin-bottom: var(--size-6);
	font-weight: var(--font-weight-semi-bold);
	font-size: var(--font-size-12);
}

.divider {
	margin: 0 var(--size-1);
	color: var(--color-secondary);
}

.textContent {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	overflow: hidden;
	-webkit-box-orient: vertical;
	word-break: break-word;

	:global(.line-break)::before {
		content: '•';
	}
}
