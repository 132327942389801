@use 'libs/theme' as *;

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: var(--size-4);
	padding-bottom: var(--size-4);

	@media (min-width: $breakpoint-lg) {
		padding-top: var(--size-6);
		padding-bottom: var(--size-6);
	}

	// TODO: This should be handled in the simple rich text component's CSS later on, instead of here.
	[class*='richtext_block__'] {
		> * {
			*:not(p) {
				&:not(:first-child) {
					margin-top: 0;
				}
			}
		}
	}
}

.icon {
	display: flex;
	align-self: flex-start;
	margin-right: -4px;
	cursor: pointer;
}

.notice {
	background-color: var(--color-light-blue-base);
}

.warning {
	background-color: var(--color-signal-yellow70);
}

.error {
	background-color: var(--color-signal-red70);
}
