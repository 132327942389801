@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-48);

	@include themed-spacing;

	&__inline {
		@media (min-width: $breakpoint-md) {
			flex-direction: row;
		}
	}
}

.text {
	&__inline {
		@media (min-width: $breakpoint-md) {
			width: (3 / 12) * 100%;
			margin-right: (1 / 12) * 100%;
		}
	}

	p {
		margin-bottom: var(--size-4);
		color: var(--color-middle-blue-base);
	}
}

.items {
	display: grid;
	grid-gap: var(--size-6);

	@media (min-width: $breakpoint-sm) {
		grid-gap: var(--size-6) var(--size-12);
		grid-template-columns: repeat(2, 2fr);
	}

	@media (min-width: $breakpoint-md) {
		grid-template-columns: repeat(3, 3fr);
	}

	@media (min-width: $breakpoint-lg) {
		grid-gap: var(--size-8) var(--size-16);
	}

	&__inline {
		@media (min-width: $breakpoint-sm) {
			grid-template-columns: repeat(2, 2fr);
		}

		@media (min-width: $breakpoint-md) {
			width: var(--grid-columns-8);
			margin-top: 0;
		}

		@media (min-width: $breakpoint-lg) {
			grid-template-columns: repeat(3, 3fr);
		}
	}
}

.item {
	display: flex;
	justify-content: space-between;
	padding: var(--size-6) 0;
	text-decoration: none;
	border-top: 1px solid var(--color-dark-blue-base);
	margin-inline: 0 !important;

	@media (min-width: $breakpoint-sm) {
		flex-direction: column;
		row-gap: var(--size-6);
	}

	@media (min-width: $breakpoint-lg) {
		padding: var(--size-8) 0;
	}

	span {
		margin-right: var(--size-5);
		font-weight: var(--font-weight-semi-bold);

		@media (min-width: $breakpoint-sm) {
			font-size: var(--font-size-18);
		}

		@media (min-width: $breakpoint-lg) {
			font-size: var(--font-size-20);
		}
	}
}
