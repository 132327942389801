@use 'libs/theme' as *;

.form_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
}

.form_warning {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--grid-columns-12);
	margin-bottom: var(--size-1);
	padding: var(--size-4) var(--responsive-spacing-36);
	background-color: var(--color-signal-yellow70);
	border-radius: var(--size-2);

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-8);
	}
}

.form_inputs {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);
	max-width: 480px;
}
